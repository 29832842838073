import { Formik } from 'formik'
import React, { Component } from 'react'
import { BaseInterestedConstraints, BaseValidatonSchema, HomeValidatonSchema } from '../constraints/ValidatonSchemas'
import Swal from 'sweetalert2';
import { checkableBoolProps, setInputProps } from '../Form'
import * as Yup from 'yup';
import ApiService from '../../../actions/services/ApiService';

export default class BaseContactFormHome extends Component {
    constructor(){
        super()
        this._api = new ApiService()
        this.handleSubmit = this.handleSubmit.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.state = {
            openModal:false,
        }
    }

    openModal(){
        this.setState({openModal:true})
    }

    closeModal(){
        this.setState({openModal:false})
    }

    initialValues = {
        fname: null,
        lname: null,
        email: null,
        phone: null,
        terms: false
    }

    handleSubmit(values, { setSubmitting, resetForm }) {
        values.url_query = localStorage.getItem("url_query")

        this._api.saveLead(values)
            .then(response => {
                setSubmitting(false)
                this.props.resetForm()
                try{
                    document.querySelector(".form").reset()
                }catch(e){
                    console.log(e)
                }
                window.location.href = "/gracias"
            })
            .catch(error => {
                setSubmitting(false)
                try{
                    document.querySelector(".form").reset()
                }catch(e){
                    console.log(e)
                }
                this.props.resetForm()
                Swal.fire({
                    title: '',
                    text: '¡Error! Intentelo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            })
    }

    render() {
        const {
            className = "form",
            initialValues,
            validationSchema,
            onSubmit,
            render
        } = this.props
        const acceptId = (Math.random() + 1).toString(36).substring(7)

        return (
            <>
                <div className={`modal ${this.state.openModal ? "active" : ""}`}>
                    <div className="content-modal">
                        <h2>Políticas de privacidad</h2>
                        <p>Usted puede ejercer sus derechos que como titular de datos personales le asisten (acceso, rectificación, cancelación, oposición, información o revocación). Para tal efecto, por favor escríbanos al siguiente correo <a href="mailto:informes@cosapiinmobiliaria.com.pe?Subject=Privacidad%20de%20datos">informes@cosapiinmobiliaria.com.pe</a> con la referencia: “Privacidad de datos”.</p>
                        <div className="close" onClick={()=>{this.closeModal()}}></div>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit || this.handleSubmit}
                    render={(form) => (
                    <form className={className} onSubmit={form.handleSubmit}>
                        
                        {render({...form})}
                        
                        <button type="submit" className="button" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando..." : "Enviar"}</button>
                    </form>
                )}/>
            </>
        )
    }
}
