import React, { Component } from 'react'
import "./promociones.scss"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

class Promociones extends Component {
  
    render() {
        return (
            <div className='promociones'>
                <div className='title-promociones'>
                    <span className='c-secondary Miftah'>Promociones</span>
                    <span className='c-primary'>pensadas para ti</span>
                </div>
                <div className='content-promos container'>
                    {
                        this.props.promos.promos.map((promo)=>{
                            return(
                                <div className='content-promo'>
                                    <Link  to={{pathname:'Promo/'+promo.link}} className='content-img cursor'>
                                        <img className='' src={promo.img} ></img>
                                    </Link>
                                    <Link to={{pathname:'Promo/'+promo.link}} className='link-promo b-third c-white'>
                                        {promo.titleBotton}
                                        <div className='icon '>
                                            <img className='' src={require('../../assets/images/inicio/icons/line-right.svg').default}></img>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

const MapStateProps = (state)=>{
    return({
        promos:state.promotionStore
    })
}
export default connect(MapStateProps)(Promociones)