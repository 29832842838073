import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    images:[
        {imgMobile:require("../../assets/images/inicio/slider/slider-1.png"),imgDesktop:require("../../assets/images/inicio/slider/slider-1-desktop.png")},
        {imgMobile:require("../../assets/images/inicio/slider/slider-1.png"),imgDesktop:require("../../assets/images/inicio/slider/slider-1-desktop.png")},
        {imgMobile:require("../../assets/images/inicio/slider/slider-1.png"),imgDesktop:require("../../assets/images/inicio/slider/slider-1-desktop.png")},
    ]
}

const sliderHead = createSlice({
    name:'sliderHead',
    initialState,
    reducers:{

    }
});

export const  { } = sliderHead.actions;
export default sliderHead.reducer