import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    slider:[
        {
            name:"tipo1",
            position:1,
            tipologias:[
                {
                    name:"Tipo x04",
                    img:require("../../assets/images/inicio/slider/tipologias/x04.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x04.png"),
                    dorms:"1 Dormitorio",
                    meters:'40.00 m²',
                },
                {
                    name:"Tipo x09",
                    img:require("../../assets/images/inicio/slider/tipologias/x09.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x09.png"),
                    dorms:"1 Dormitorio",
                    meters:'41.0 m²',
                },
            ],
            features:[
                "1 Baño • Sala • Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
    ],
    sliderLanzamiento:[
        {
            name:"tipo2",
            position:2,
            tipologias:[
                {
                    name:"Tipo X02",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X02B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X02.png"),
                    dorms:"2 Dormitorios",
                    meters:'58.60 m²',
                },
                {
                    name:"Tipo X04",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X04B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X04.png"),
                    dorms:"2 Dormitorios",
                    meters:'52.70 m²',
                },
                {
                    name:"Tipo X05",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X05B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X05.png"),
                    dorms:"2 Dormitorios",
                    meters:'53.60 m²',
                },
                {
                    name:"Tipo X06",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X06B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X06.png"),
                    dorms:"2 Dormitorios",
                    meters:'50.00 m²',
                },
                {
                    name:"Tipo X07",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X07B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X07.png"),
                    dorms:"2 Dormitorios",
                    meters:'48.50 m²',
                },
            ],
            features:[
                "2 baños • Sala • Comedor", 
                "Cocina • Lavandería • Balcón",
            ]
        },
    ],
    index:0,
    indexLanzamiento:0,
    tipologia:0,
    tipologiaLanzamiento:0
}

const departamentos = createSlice({
    name:"departamentos",
    initialState,
    reducers:{
        updateInf:(state,value)=>{
            state.tipologia = value.payload
        },
        updateTipologiesData:(state,value)=>{
            state.slider = [value.payload]
            state.tipologia = 0
       },
        updateInfLanzamiento:(state,value)=>{
            state.tipologiaLanzamiento = value.payload
        },
        updateTipologiesDataLanzamiento:(state,value)=>{
            state.sliderLanzamiento = [value.payload]
            state.tipologiaLanzamiento = 0
       },
    }
})
export const { updateInf,updateTipologiesData,updateInfLanzamiento,updateTipologiesDataLanzamiento} = departamentos.actions
export default departamentos.reducer