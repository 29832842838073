import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import "./footer.scss"
import { connect } from 'react-redux'
class Footer extends Component {
    render() {
        if(this.props.value.navShow){
            return (
                <footer className="Footer b-black" id="footer">
                    <div className="all container">
                    <MediaQuery query='(min-width:500px)'>
                        <div className="content-logo">
                            <MediaQuery query='(min-width:500px)'>
                                <div className="logo">
                                    <img src={require("../../assets/images/brand-ativa-white.svg").default}></img>
                                </div>
                            </MediaQuery>
                        </div>
                    </MediaQuery>
                        <div className="footer_contacto flex-column">
                            <div className='c-white d-movil'>
                               <span className='ultra'>Comunícate</span>con nosotros            
                            </div>
                            <div className='content-link-contact'>
                                <p className="contactanos">Contáctanos</p>
                                <a target="__blank" href="https://api.whatsapp.com/send?phone=51966874541&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Ativa%20inmobiliaria" className="contact flex-row c-white" id="icon-1">
                                    <img className='' src={require('../../assets/images/inicio/icons/whatsapp.svg').default} ></img>
                                    +51 966 874 541

                                </a>
                                <a className="contact flex-row c-white" id="icon-3" target="__blank" href="mailto:comercial@ativa.pe">
                                    <img className='' src={require('../../assets/images/inicio/icons/correo-footer.svg').default} ></img>
                                    comercial@ativa.com.pe
                                </a>
                                <a className="contact flex-row c-white" id="icon-3" target="__blank" href="https://maps.app.goo.gl/8Pyaona87aMLvRGa8">
                                    <img className='' src={require('../../assets/images/inicio/icons/place-footer.svg').default} ></img>
                                    Av. El Derby 160, Surco
                                </a>
                            </div>
                        </div>
                        <a href="https://www.bestplacetolive.com.pe/" target='_blank' className='flex-column contacto'>
                            <span className='title-brand-best-place c-white'>Certificado por</span>
                            <img className='img-best-place' src={require('../../assets/images/inicio/icons/best_place.svg').default} ></img>
                        </a>
                        <div className="brands">
                            <div className='brand-top'>
                                <div className="content-brand">
                                    <a href="https://www.cosapiinmobiliaria.com.pe/" target="_blank" className="d-block cosapi">
                                    <img className='' src={require('../../assets/images/inicio/icons/brand-cosapi.png')} ></img>
                                    </a>
                                </div>
                                <span className="c-white add">+</span>
                                <div className="content-brand">
                                    <a href="https://gerpal.pe/" target="_blank" className="d-block gerpal">
                                        <img className='' src={require('../../assets/images/inicio/icons/gerpal-white.png')} ></img>
                                    </a>
                                </div>
                            </div>
                            <div className='brand-down'>
                                <a href="https://adiperu.pe/" target="_blank" className="d-block item">
                                    <img className='' src={require('../../assets/images/inicio/icons/adiperu.png')} ></img>
                                </a>
                                <a href="https://dci.pe/" target="_blank" className="d-block item">
                                    <img className='' src={require('../../assets/images/inicio/icons/CDI.png')} ></img>
                                </a>
                                <a href="https://asei.com.pe/" target="_blank" className="d-block item asei">
                                    <img className='' src={require('../../assets/images/inicio/icons/asei-cosapi.png')} ></img>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex content-barra-footer container">
                        <div className='column-1 d-flex'>
                            <div className='item'>
                                <a className='c-white link' href={require("../../assets/files/TERMINOS-Y-CONDICIONES-ATIVA.pdf")} target='_blank' >Téminos y condiciones</a>
                                {/* <span className='c-white link'>Código de protección al consumidor</span> */}
                                <a className='c-white link' href={require("../../assets/files/Politica-de-privacidad.pdf")} target='_blank'>Políticas de privacidad de datos</a>
                            </div>
                            <a className="item" href="/libro-de-reclamaciones">
                                <img className="icon" src={require('../../assets/images/inicio/icons/libro-de-reclamaciones.jpg')}></img>
                            </a>
                        </div>
                        <MediaQuery query='(max-width:500px)'>
                            <div className="logo-cosapi">
                                <a target="_blank" href="http://adiperu.pe/">
                                    <img className="img-3" src={require("../../assets/images/inicio/icons/adiperu.png")}></img>
                                </a>
                                <a target="_blank" href="https://www.asei.com.pe/">
                                    <img src={require("../../assets/images/inicio/icons/CDI.png")} className='dci'></img>
                                </a>
                                <img className='asei' src={require('../../assets/images/inicio/icons/asei-cosapi.png')} ></img>
                            </div>
                        </MediaQuery>
                        <MediaQuery query='(max-width:500px)'>
                            <div className='best_place'>
                                <span className='c-white'>Certificado por</span><img className='' src={require("../../assets/images/inicio/icons/best_place.svg").default} ></img>
                            </div>
                        </MediaQuery>
                        <div className='certificado'>
                        </div>
                        <div className="item">
                            <span className='link c-white gotham'>© 2021 Proyecto Ativa. Todos los derechos reservados</span>
                            <span className='link c-white gotham'>Proyecto Ativa cuenta con 3 fases y está abierto a posibles modificaciones.</span>
                            <span className="c-white link gotham">
                                   Creado con ♡ por <a className="c-white gotham" href="https://www.formulaperu.com/" target="_blank">Fórmula Agencia de Marketing</a>
                            </span>
                        </div>
                    </div>
                </footer>
            )
        }else{
            return(
                <></>
            )
        }
    }
}

function mapStateProps(state){
    return{
        value:state.routesFeatures
    }
}

export default connect(mapStateProps,null)(Footer)