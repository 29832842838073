import React, { Component } from 'react'
import * as Yup from 'yup';
import { checkableBoolProps, setInputProps } from '../../components/common/forms/Form'
import BaseContactForm from '../../components/common/forms/markup/BaseContactForm'
import { Fragment } from 'react';
import './contacto.scss'
import { fases } from '../inicio/script';
import BaseContactFormHome from '../../components/common/forms/markup/BaseContactFormHome';
import { FormHomeSchema } from '../../components/common/forms/constraints/ValidatonSchemas';
export default class Contactanos extends Component {
    constructor(props) {
        super(props)
        this.state = {
           initialValues:{
                fname: null,
                lname: null,
                email: null,
                phone: null,
                terms: false,
                phase:"",
                rooms_amount: "",
                motive: "",
                message: ""
            },
        }
    }
     setterTerms = (e)=>{
        this.setState(prevState => ({
            initialValues: {                   
                ...prevState.initialValues,    
                terms: true   
            }
        }))
    }
    setter = (e)=>{
        this.setState(prevState => ({
            initialValues: {                 
                ...prevState.initialValues,  
                [e.target.name]:e.target.value       
            }
        }))
       
        let delay = setInterval(() => {
            console.log(this.state)
            clearInterval(delay);
        }, 1000);
    }
    resetForm = () => {
        this.setState(prevState => ({
            initialValues:{
                fname: null,
                lname: null,
                email: null,
                phone: null,
                terms: false,
                phase:"",
                rooms_amount: "",
                motive: "",
                message: ""
            },
        }))
    }
    changeFase =(e)=>{
        try{
            document.getElementsByName("rooms_amount")[0].value = "";
            this.setState(prevState=>({
                ...prevState,
                initialValues:{
                    ...prevState.initialValues,
                    phase:e.target.value
                }
            }));
        }catch(error){
            console.log(error)
        }
    }

    render() {
        return (
            <div className='landing contactanos'>
                <section className='section-form'>
                    <img className='model' src={require('../../assets/images/inicio/icons/background-model.png')} ></img>
                    <div className="content-landing">
                        <div className="content-formulario">
                            <div className='content-title-form'>
                                <span className="sub-title bold-alt">¿Aún no has agendado una cita para conocer tu nuevo depa?</span>
                                <span className="sub-title-2 c-primary light">Deja tus datos en el siguiente formulario y conversemos</span>
                            </div>
                            <BaseContactFormHome
                                initialValues={this.state.initialValues}
                                resetForm={this.resetForm}
                                validationSchema={
                                FormHomeSchema
                                }
                                render={(form) => (
                                    <Fragment>
                                        <input type="text" {...setInputProps("fname", "input col", form)} onChange={(e)=>{this.setter(e)}} placeholder="Nombres*"></input>
                                        <input type="text" {...setInputProps("lname", "input col", form)}  onChange={(e)=>{this.setter(e)}} placeholder="Apellidos*"></input>
                                        <input type="tel" {...setInputProps("phone", "input col", form)}  onChange={(e)=>{this.setter(e)}} placeholder="N° de celular*"></input>
                                        <input type="text" {...setInputProps("email", "input col", form)}  onChange={(e)=>{this.setter(e)}} placeholder="Correo electrónico*"></input>
                                        <select 
                                            {...setInputProps("phase", "input col", form)}
                                            onChange={(e)=>{this.changeFase(e)}} 
                                        >
                                            <option value="">Fase</option>
                                            {
                                                fases.map((item,index)=>{
                                                    return(
                                                        <option value={item.value} key={index+"fase"}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <select {...setInputProps("rooms_amount", "input col", form)} onChange={(e)=>{this.setter(e)}}>
                                            <option value="">N° de dormitorios</option>
                                            {
                                                this.state.initialValues.phase ?
                                                fases.filter((e)=>{return e.value == this.state.initialValues.phase})[0].dorms.map((item,index)=>{
                                                    return(
                                                        <option value={item.value} key={index+"rooms"} >{item.name}</option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                        <select {...setInputProps("motive", "input w-100", form)} onChange={(e)=>{this.setter(e)}}>
                                            <option value="">Motivo</option>
                                            <option value='Primera vivienda'>Primera vivienda</option>
                                            <option value='Inversión'>Inversión</option>
                                        </select>
                                        <span className="terms">
                                            <input id={"check_terms"} {...checkableBoolProps("terms", "checkbox", form)} onChange={(e)=>{this.setterTerms(e)}} type="checkbox"></input>
                                            <label htmlFor={"check_terms"} className="label-accept"><div className="check"></div></label>
                                            Acepto las <a href={require("../../assets/files/Politica-de-privacidad.pdf")} target="_blank" className="cursor-pointer" >Políticas de privacidad</a>
                                        </span>
                                </Fragment>
                            )}
                        />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
