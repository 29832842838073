export const onChangeInput =(setter,select)=>{
    setter({fase:select.target.value});
}

export const fases = [
    {
        name:"Fase 1 - Preventa",
        value: 17,
        dorms:[
            {name:"1 Dormitorio",value:"1 Dormitorio"},
            {name:"2 Dormitorios",value:"2 Dormitorios"},
            {name:"3 Dormitorios",value:"3 Dormitorios"},
        ]
    },
    {
        name:"Fase 2 - Preventa",
        value: 18,
        dorms:[
            {name:"2 Dormitorios",value:"2 Dormitorios"},
            {name:"3 Dormitorios",value:"3 Dormitorios"},
        ]
    },
    {
        name:"Fase 3 - Lanzamiento",
        value: 18,
        dorms:[
            {name:"2 Dormitorios",value:"2 Dormitorios"},
            {name:"3 Dormitorios",value:"3 Dormitorios"},
        ]
    },
]