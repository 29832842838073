import React, { Component } from 'react'
import { BrowserRouter as  Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { showNav } from '../../data/routeStore'
class Head extends React.PureComponent{
    render (){
        return (
        <>
            <Helmet>
                <title>{this.props.title}</title>
            </Helmet>
        </>
        )
    }
}
class PublicRoutes extends Component {
    constructor(props){
        super(props);
    }
    componentDidUpdate(prev){
        window.scrollTo(0,0)
    }
    componentDidMount(){
        this.props.showNav()
        window.scrollTo(0,0)
        let time = setInterval(() => {
            window.scrollTo(0,0)
            clearInterval(time)
        }, 200);
    }
    render() {
        return (
            <div className={this.props.item.title}>
                {/* <Head title={"ATIVA | "+this.props.item.title}></Head> */}
                <Helmet>
                    <title>{"ATIVA | "+this.props.item.title}</title>
                    <meta name='keyword' content={this.props.item.metaKeyword}></meta>
                    <meta name='description' content={this.props.item.metaDescription}></meta>
                </Helmet>
                {this.props.item.component}
            </div>
        )
    }
}
export default connect(null,{showNav})(PublicRoutes)
