export const data = {
    fase1:[
        {
            name:"tipo1",
            position:1,
            tipologias:[
                {
                    name:"Tipo x04",
                    img:require("../../assets/images/inicio/slider/tipologias/x04.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x04.png"),
                    dorms:"1 Dormitorio",
                    meters:'40.00 m²',
                },
                {
                    name:"Tipo x09",
                    img:require("../../assets/images/inicio/slider/tipologias/x09.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x09.png"),
                    dorms:"1 Dormitorio",
                    meters:'41.0 m²',
                },
            ],
            features:[
                "1 Baño • Sala • Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
        {
            name:"tipo2",
            position:2,
            tipologias:[
                {
                    name:"Tipo x02",
                    img:require("../../assets/images/inicio/slider/tipologias/x02.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x02.png"),
                    dorms:"2 Dormitorios",
                    meters:'50.30 m²',
                },
                {
                    name:"Tipo x03",
                    img:require("../../assets/images/inicio/slider/tipologias/x03.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x03.png"),
                    dorms:"2 Dormitorios",
                    meters:'59.50 m²',
                },
                {
                    name:"Tipo x06",
                    img:require("../../assets/images/inicio/slider/tipologias/x06.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x06.png"),
                    dorms:"2 Dormitorios",
                    meters:'53.00 m²',
                },
                {
                    name:"Tipo x07",
                    img:require("../../assets/images/inicio/slider/tipologias/x07.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x07.png"),
                    dorms:"2 Dormitorios",
                    meters:'47.20 m²',
                },
                {
                    name:"Tipo x08",
                    img:require("../../assets/images/inicio/slider/tipologias/x08.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x08.png"),
                    dorms:"2 Dormitorios",
                    meters:'48.00 m²',
                },
            ],
            features:[
                "2 Baños • Sala • Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
        {
            name:"tipo3",
            position:3,
            tipologias:[
                {
                    name:"Tipo x01",
                    img:require("../../assets/images/inicio/slider/tipologias/x01.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x01.png"),
                    dorms:"3 Dormitorios",
                    meters:'69.60m²',
                },
                {
                    name:"Tipo x05",
                    img:require("../../assets/images/inicio/slider/tipologias/x05.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/ubi_x05.png"),
                    dorms:"3 Dormitorios",
                    meters:'71.00 m²',
                },
                
            ],
            features:[
                "2 Baños • Sala • Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
    ],
    fase2:[
        {
            name:"tipo2",
            position:2,
            tipologias:[
                {
                    name:"Tipo X02",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X02B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X02.png"),
                    dorms:"2 Dormitorios",
                    meters:'58.60 m²',
                },
                {
                    name:"Tipo X04",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X04B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X04.png"),
                    dorms:"2 Dormitorios",
                    meters:'52.70 m²',
                },
                {
                    name:"Tipo X05",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X05B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X05.png"),
                    dorms:"2 Dormitorios",
                    meters:'53.60 m²',
                },
                {
                    name:"Tipo X06",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X06B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X06.png"),
                    dorms:"2 Dormitorios",
                    meters:'50.00 m²',
                },
                {
                    name:"Tipo X07",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X07B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X07.png"),
                    dorms:"2 Dormitorios",
                    meters:'48.50 m²',
                },
            ],
            features:[
                "2 Baños • Sala • Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
        {
            name:"tipo3",
            position:3,
            tipologias:[
                {
                    name:"Tipo X01",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X01B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X01.png"),
                    dorms:"3 Dormitorio",
                    meters:'68.00 m²',
                },
                {
                    name:"Tipo X03",
                    img:require("../../assets/images/inicio/slider/tipologias/lanzamiento/ATIVA-X03B.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/lanzamiento/X03.png"),
                    dorms:"3 Dormitorio",
                    meters:'69.50 m²',
                },
            
            ],
            features:[
                "2 Baños • Sala • Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
    ],
    fase3:[
        {
            name:"tipo3",
            position:2,
            tipologias:[
                {
                    name:"Tipo X01",
                    img:require("../../assets/images/inicio/slider/tipologias/fase3/tipologia-x01.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/fase3/MAPA-X01.png"),
                    dorms:"2 Dormitorios",
                    meters:'56.02 m²',
                },
                {
                    name:"Tipo X02",
                    img:require("../../assets/images/inicio/slider/tipologias/fase3/tipologia-x02.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/fase3/MAPA-X02.png"),
                    dorms:"2 Dormitorios",
                    meters:'55.84 m²',
                },
                {
                    name:"Tipo X03",
                    img:require("../../assets/images/inicio/slider/tipologias/fase3/tipologia-x03.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/fase3/MAPA-X03.png"),
                    dorms:"2 Dormitorios",
                    meters:'54.00 m²',
                },
                {
                    name:"Tipo X04",
                    img:require("../../assets/images/inicio/slider/tipologias/fase3/tipologia-x04.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/fase3/MAPA-X04.png"),
                    dorms:"2 Dormitorios",
                    meters:'59.01 m²',
                },
            ],
            features:[
                "2 Baños • Sala Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
        {
            name:"tipo3",
            position:3,
            tipologias:[
                {
                    name:"Tipo X05",
                    img:require("../../assets/images/inicio/slider/tipologias/fase3/tipologia-x05.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/fase3/MAPA-X05.png"),
                    dorms:"3 Dormitorios",
                    meters:'72.81 m²',
                },
                {
                    name:"Tipo X06",
                    img:require("../../assets/images/inicio/slider/tipologias/fase3/tipologia-x06.png"),
                    sketch:require("../../assets/images/inicio/slider/tipologias/fase3/MAPA-X06.png"),
                    dorms:"3 Dormitorios",
                    meters:'70.54 m²',
                },
            
            ],
            features:[
                "2 Baños • Sala Comedor",
                "Cocina • Lavandería • Balcón",
            ]
        },
    ],
}