import { useEffect,useState } from 'react';
import './thanks.scss';
// import { DatoSuccess } from './modalLead/dataSuccess';
const callbackDelay = (callback, delay) => {
	let delayInterval = setInterval(
		() => {
			callback();
			clearInterval(delayInterval);
		},
		delay ? delay : 1000
	);
};
export const Thanks = () => {
	const [animation, setAnimation] = useState('');
	const returnHistory = () => {
		window.history.back();
	};
	useEffect(()=>{
		try{
			callbackDelay(() => setAnimation('active'), 500);
			!location.hostname.includes("localhost") && gtag('event', 'conversion', {'send_to': 'AW-11107816690/ZnqzCNm6xZMYEPKpz7Ap'})
		}catch (e) {
			console.log(e)
		}
	}, [])
	return (
		<main className='thanks'>
			<div className='absolute circle-blur circle-sky circle-position-left'></div>
			<div className='absolute circle-blur circle-purple circle-position-right'></div>
			<picture className='background-thanks-balls-top'>
				<source media='(min-width: 500px)' srcSet={require('../../assets/images/thanks/background-balls.svg').default} />
				<img className='background-thanks-balls' src={require('../../assets/images/thanks/balls.png')} alt='' />
			</picture>
			<img className='background-thanks-balls-middle' src={require('../../assets/images/thanks/background-balls-middle.svg').default} alt='' />
			<div className='content-thanks'>
				<div className=''>
					<img className='background-thanks' src={require('../../assets/images/thanks/padres.png')} alt='' />
				</div>
				<div className='content-info'>
					<div className='info'>
						<div className={'success ' + animation}>
							<div className='check'>
								<div className='content-check'>
									<div className='line-1'></div>
									<div className='line-2'></div>
								</div>
							</div>
						</div>
						<h1 className='title'>DATOS ENVIADOS</h1>
						<p className='paragraph'>En breve un asesor se comunicará con usted para más información</p>
					</div>
					{/* <a href='https://wa.me/51953950823?text=Hola, quiero más información sobre Ativa' className='button bg-third text-white mb-3 flex'>
						<div className=' mr-3 mask icon-whatsapp bg-white w-6 h-6'></div>
						<span className=''>
							Hablar con un asesor
						</span>
					</a> */}
					<div className='button button-return flex items-center border border-gray-100' onClick={() => returnHistory()}>
						<span>
							Volver
						</span>
						<div className='mask ml-auto icon-signal-right bg-gray-400 w-5 h-5'></div>
					</div>
				</div>
			</div>
		</main>
	);
};
