import * as Yup from 'yup';
export const baseSchema = (values) =>
	Yup.object().shape({
		fname: Yup.string().matches(/([A-Za-z\d])+/g, 'No debe contener números').required(),
		lname: Yup.string().matches(/([A-Za-z\d])+/g, 'No debe contener números').required(),
		phone: Yup.string()
			.matches(/[\+?\-?0-9]{7,}/)
			.required(),
		email: Yup.string().email().required(),
		terms: Yup.bool().oneOf([true]).required(),
	});