import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Footer from './components/footer/footer'
import { connect } from 'react-redux'
import { showNav } from './data/routeStore'
import PublicRoutes from './components/helpers/PublicRoutes'
import Navbar from './components/navbar/navbar'
import PrivatedRoute from './components/helpers/PrivatedRoute'
import Dashboard from './pages/dashboard/dashboard'
import Whatsapp from './components/whatsapp/whatsapp'
import Phone from './components/phone/phone'
import Popup from './components/popup/popup'
import { FloatForm } from './components/floatform/floatform'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
// import Page from './component/range/page'

class Routes extends Component {

    componentDidMount() {
        function esDispositivoiOS(){
            var userAgent = window.navigator.userAgent;
            let typeDevice = (/iPhone|iPod|iPad|Mac/.test(userAgent));
            if(typeDevice){
                document.querySelector("#root").classList.add("IOS")
            }
        }
        esDispositivoiOS()
    }
      render() {
    return (
        <Router>
            
            <Navbar></Navbar>
            <Whatsapp></Whatsapp>
           
            {/* <Popup></Popup> */}
            <Switch>
                {
                    this.props.value.links.map((item,index)=>{
                        return(
                            <Route exact path={item.link} key={index-'route'}>
                                <PublicRoutes item={item}>
                                    {item.component}    
                                </PublicRoutes>
                            </Route>
                        )
                    })
                }
                <PrivatedRoute exact path='/dashboard' key='dasboard'>
                    <Dashboard></Dashboard>
                </PrivatedRoute>
                <Redirect to='/' />
            </Switch>
            {
                window.location.href.includes('gracias') ? null :  <FloatForm/>
            }
           
            <Footer></Footer>
        </Router>
    )
  }
}

function mapStateProps(state){
  return {
      value:state.routesFeatures,
  }
}
export default connect(mapStateProps,{showNav})(Routes)
