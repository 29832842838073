import { createSlice } from '@reduxjs/toolkit'
import Login from '../pages/formularios/login'
import Inicio from '../pages/inicio/inicio'
import Promociones from '../pages/promociones/promociones'
import Aliados from '../pages/aliados/aliados'
import Promo from '../pages/promociones/promo'
import Departamentos from '../pages/departamentos/departamentos'
import LoginRegister from '../pages/formularios/loginRegister'
import Sociales from '../pages/sociales/sociales'
import Contactanos from '../pages/formularios/contacto'
import Reclamacion from '../pages/reclamacion/reclamacion'
import { Thanks } from '../pages/Thanks/thanks'
const meta = 'Proyecto, inmobiliario, Surco, centro comercial, El Polo, depas, departamento, departamentos,1 dorm, 2 dorms, 3 dorms, 1 dormitorio, 2 dormitorios, 3 dormitorios, casa propia, Bono Verde, Cosapi Inmobiliaria, Gerpal, MiVivienda, Lanzamiento, áreas comunes, nexo inmobiliario, adondevivir'
const  initialState = {
    activeLinkValue: 0,
    navShow:true,
    links:[
        {
            index:0,
            title:"Inicio",
            link:"/",
            component:<Inicio/>,
            metaDescription:'Ativa es un proyecto de Cosapi Inmobiliaria y Gerpal ubicado en Surco a pocas cuadras del C.C El Polo. Departamentos de 1, 2 y 3 dormitorios con áreas comunes de lujo.',
            metaKeyword:meta,
        },
        {
            index:2,
            title:"Departamentos",
            link:"/Departamentos",
            metaDescription:'El departamento que necesitas en Surco lo tenemos aquí. Depas de 1, 2 y 3 dormitorios de 40m2 hasta 71m2 en la Av. El Derby 160. Proyecto en Pre lanzamiento.',
            metaKeyword:meta,
            component:<Departamentos/>,
        },
        {
            index:3,
            title:"Áreas Sociales",
            link:"/areas-sociales",
            metaDescription:'Ativa cuenta con una gran variedad de áreas comunes para que no necesites salir de tu hogar. Más de 20 espacios como Sala Hollywood, Fire Place, Think Box, Sala de reuniones, Sky Bar y muchos más',
            metaKeyword:meta,
            component: <Sociales/>,
        },
        {
            index:4,
            title:"Promociones",
            icon:require('../assets/images/inicio/icons/gift.svg').default,
            link:"/Promociones",
            metaDescription:'¡Mira la promo que tenemos para ti! Queremos que cumplas tus sueños y des ese gran salto en tu vida.',
            metaKeyword:meta,
            component:<Promociones/>,
        },
        // {
        //     index:5,
        //     title:"Aliados",
        //     link:"/aliados",
        //     component: <Aliados/>,
        // },
        {
            index:6,
            title:"Preguntas frecuentes",
            link:"/preguntas-frecuentes",
            component:null,
            show:false,
        },
        {
            index:7,
            title:"Contáctanos",
            link:"/contactanos",
            metaDescription:'¿Tienes alguna duda? ¿Quieres comunicarte con un asesor? Contáctanos y conversemos.',
            metaKeyword:meta,
            component:<Contactanos></Contactanos>,
        },

        {
            index:100,
            title:"Promo",
            link:"/Promo/:id",
            component:<Promo/>,
            show:false,
        },
        {
            index:101,
            title:"Login",
            link:"/login",
            component:<Login/>,
            show:false,
        },
        {
            index:102,
            title:"Registro",
            link:"/registro",
            component:<LoginRegister/>,
            show:false,
        },
        {
            index:103,
            title:"Libro de Reclamaciones",
            link:"/libro-de-reclamaciones",
            component:<Reclamacion></Reclamacion>,
            show:false,
        },
        {
            index:103,
            title:"Gracias",
            link:"/gracias",
            component:<Thanks/>,
            show:false,
        },
    ]
}
export const routesFeatures = createSlice({
    name:"routes",
    initialState,
    reducers:{
        activeLinks: (state,value)=>{
            state.activeLinkValue = value.payload
        },
        hideNav:(state)=>{
            state.navShow = false
        },
        showNav:(state)=>{
            state.navShow = true
        },
    }
})

export const {activeLinks, hideNav, showNav} = routesFeatures.actions
export default routesFeatures.reducer