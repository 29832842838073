import React, { Component } from 'react'

export default class MainDash extends Component {
    render() {
        return (
            <div className="features dash">
                <p className='paragraph'>Dash Main</p>
            </div>
        )
    }
}
