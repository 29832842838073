import React, { Component } from 'react'
import './navbar.scss'
import { connect } from 'react-redux'
import { activeLinks } from '../../data/routeStore'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive';

class Navbar extends Component {
    constructor(props){
        super(props)
        this.animationLine = this.animationLine.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.leaveSearch = this.leaveSearch.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.focusSearch = this.focusSearch.bind(this)
        this.click = this.click.bind(this)
        this.state = {
            activeLine:1,
            value:"",
            results:[],
            active:false,
            toggle:false,
            closeMore:false,
        }
       
    }
    componentDidMount(){
        let location = this.props.value.links.filter((item,index)=>{
            let dataLocation
            if( window.location.href.includes(item.link)){
                this.props.activeLinks(item.index)
            }
            return dataLocation
        })
        
    }
    resetComponent = () => this.setState({ isLoading: false, results: [], value: '',active:false})

    animationLine(e,position,func){
        this.setState({activeLine:position})
    
    }
    alert(){
        console.log("alert")
    }
    addItem(){
        this.setState({isPaused:false,isStopped:false})
        let time = setInterval(() => {
            this.setState({isPaused:true,isStopped:true})
            clearInterval(time)
        }, 1000);
    }
    handleSearchChange(e){
        this.setState({value:e.value})
        if(e.value.length < 1) return this.resetComponent()
        if(e.value.length > 1) this.setState({active:true})
        let item = this.state.data.filter((item)=>{
            if(item.title.search(new RegExp(e.value,"i")) == 0) return item
        })
        this.setState({results:item})
    }
    focusSearch(){
        this.setState({active: true})
    }
    selectItem(item){
        this.setState({results: [item], value: item.title ,active:false})
    }
    leaveSearch(item){
        console.log("this leave")
        this.setState({active: false})
    }
    click(e){
        e.addItem()
    }
    toggle(e){
        this.setState({toggle:!this.state.toggle})
    }
    close=()=>{
        this.setState({closeMore:true})
    }
    render() {
        if(this.props.value.navShow){
            return (
                <nav className="navbar">
                    <div className={`more b-primary ${this.state.closeMore ? "close":""}`}>
                        <div className="container">
                            <span className="sub-title sub-title-1 c-white"><img className='' src={require('../../assets/icons/cosapi-brand.png')} ></img></span>
                            <div className="barra"></div>
                            <span className="sub-title c-white">Conoce todos nuestros proyectos  </span>
                            <a href="https://www.cosapiinmobiliaria.com.pe/" className="b-white c-primary btn-know">
                                <span className='flex'>
                                    Aquí
                                </span>
                            </a>
                            <div className="button" onClick={(e)=>{this.close()}}>
                                <div className="add"></div>
                            </div>
                        </div>
                    </div>
                    <div className="container nav-container">
                        <a href="/" className="brand">
                            <img src={require("../../assets/images/iconos/ativa-brand.svg").default}></img>
                        </a>
                        <div className={`container-link ${this.state.toggle == true ? "active" : "inactive"}`}>
                            <div className='center'>
                                {
                                    this.props.value.links.map((item,index)=>{
                                        if(item.show != false){
                                            return(
                                            <Link to={item.link} key={"link-"+index} onClick={(e)=>{this.toggle(e)}}>
                                                <div className={`link ${item.index == this.props.value.activeLinkValue ? "active" : ""}`}
                                                    onClick={()=>{this.props.activeLinks(item.index)}}
                                                >
                                                    <span className="text-link">{item.title}{item.icon ? <img className='icon' src={item.icon}></img> : ''}</span>
                                                    <span className="line"></span>
                                                </div>
                                            </Link>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className="link-buttons">
                                <a  target="_blank" href="https://www.facebook.com/cosapiinmobiliariaoficial/" className={`link button`}>
                                    <div className="redes mask facebook">
                                        <img className='' src={require('../../assets/images/inicio/icons/facebook.svg').default} ></img>
                                    </div>
                                </a>
                                <a target="_blank" href="https://www.instagram.com/cosapi.inmobiliaria/" className={`link button`}>
                                    <div  className="redes mask instagram">
                                        <img className='' src={require('../../assets/images/inicio/icons/instagram.svg').default} ></img>
                                    </div>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCfr9B6LMPgZDI6LVSsIA-yQ" className={`link button`}>
                                    <div  className="redes mask youtube">
                                        <img className='' src={require('../../assets/images/inicio/icons/youtube.svg').default} ></img>
                                    </div>
                                </a>
                                <a target="_blank" href="https://pe.linkedin.com/company/cosapiinmobiliaria" className={`link button`}>
                                    <div  className="redes mask linkdin">
                                        <img className='' src={require('../../assets/images/inicio/icons/linkedin.svg').default} ></img>
                                    </div>
                                </a>
                                <a target="_blank" href={require('../../assets/images/inicio/Ativa_brochure.pdf')} className={`link button`}>
                                    <div  className="redes mask brochure">
                                        <img className='' src={require('../../assets/images/inicio/icons/brochure.svg').default} ></img>
                                        Brochure
                                    </div>
                                </a>
                                
                                
                            </div>
                        </div>
                        <MediaQuery query="(max-width: 1200px)">
                            <div className={`toggle ${this.state.toggle == true ? "active" : ""}`} onClick={(e)=>{this.toggle(e)}}>
                                <div className="line line-1"></div>
                                <div className="line line-2"></div>
                            </div>
                        </MediaQuery>
                    </div>
                </nav>
            )
        }else{
            return(
                <div></div>    
            )
        }
    }
}
function mapStateProps(state){
    return{
        value:state.routesFeatures
    }
}
export default connect(mapStateProps,{ activeLinks })(Navbar)