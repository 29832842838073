import React, { Component } from 'react'
import './map.scss'
import MediaQuery from 'react-responsive'
var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
mapboxgl.accessToken = 'pk.eyJ1IjoiZWR1YXJkb2p2cCIsImEiOiJja2xsMDN1ZXowN2cxMnVsY3FpZzllcTB3In0.VqhrTGX7rFTgSeROj01uzQ';
const signal_left =  require("../../assets/images/inicio/icons/signal-left.svg").default
const signal_right =  require("../../assets/images/inicio/icons/signal-right.svg").default

export default class Map extends Component {
    constructor(props){
        super(props)
        this.state = {
            active:0,
            icons:[
                {project:'Estudios',icon:require('../../assets/images/inicio/icons/education.svg').default},
                {project:'Diversión',icon:require('../../assets/images/inicio/icons/commerce.svg').default},
                {project:'Corporativo',icon:require('../../assets/images/inicio/icons/build.svg').default},
                {project:'Recreación',icon:require('../../assets/images/inicio/icons/relax.svg').default},
            ],
            ubicaciones:[
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97168635918554,-12.098073799394646,]
                    },
                    properties: {
                        section:'Proyecto',project:"ativa",index:'19'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97369387177244,-12.093153494268412]
                    },
                    properties: {
                        section:'Proyecto',project:"epiqe",index:'20'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97100950227022,-12.084692818209767]
                    },
                    properties: {
                        section:'Estudios',project:"Universidad de Lima",index:'1'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97321840795139,-12.092362383797889]
                    },
                    properties: {
                        section:'Estudios',project:"Colegio Peruano Británico",
                        index:'2'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.96514714738085,-12.09561955983428]
                    },
                    properties: {
                        section:'Estudios',project:"Colegio Salcantay",
                        index:'3'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.96523737252117,-12.097424470538591]
                    },
                    properties: {
                        section:'Estudios',project:"Colegio Pio XII",
                        index:'4'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.9790825013565,-12.09898062865714]
                    },
                    properties: {
                        section:'Estudios',project:"Colegio Saco Oliveros",
                        index:'5'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97583049155757,-12.09866609042367]
                    },
                    properties: {
                        section:'Estudios',project:"Markham College Lower School",
                        index:'6'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.96285908786248,-12.103882655983059]
                    },
                    properties: {
                        section:'Estudios',project:"UPC",
                        index:'7'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97637425902744,-12.085656987386965]
                    },
                    properties: {
                        section:'Diversión',project:"Jockey Plaza",
                        index:'8'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.98031441669814,-12.085656987386965]
                    },
                    properties: {
                        section:'Diversión',project:"YOY Lima Box Park",
                        index:'9'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.9772696301919,-12.092119885034103]
                    },
                    properties: {
                        section:'Diversión',project:"Hipódromo",
                        index:'10'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97153910689893,-12.102300674171918]
                    },
                    properties: {
                        section:'Diversión',project:"C.C. El Polo",
                        index:'11'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97253498898911,-12.097871620438744]
                    },
                    properties: {
                        section:'Corporativo',project:"Center Tower",
                        index:'12'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.9689561320393,-12.100422620520405]
                    },
                    properties: {
                        section:'Corporativo',project:"Embajada de Estados Unidos",
                        index:'13'
                    }
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.96474701921325,-12.086154994917269]
                    },
                    properties: {
                        section:'Recreación',project:"Los Incas Golf Club",
                        index:'14'
                    }
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97454590377123,-12.097693789790654]
                    },
                    properties: {
                        section:'Recreación',project:"Jockey Club",
                        index:'15'
                    }
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.9667727050515,-12.099588300700058]
                    },
                    properties: {
                        section:'Recreación',project:"Lima Polo Club",
                        index:'16'
                    } 
                },
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.96404721881765,-12.099947239900569]
                    },
                    properties: {
                        section:'Recreación',project:"Centro de Esparcimiento Fundo Odría",
                        index:'17'
                    } 
                },
            ],

            map:'',
            
            category:[
                {   
                    type:'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [-76.97100950227022,-12.084692818209767]
                    },
                    properties: {
                        section:'Estudios',project:"Universidad de Lima",index:'1'
                    } 
                },
            ],
            indexCategory:0,
        } 
    }
     componentDidMount(){
        mapboxgl.accessToken = 'pk.eyJ1IjoiZWR1YXJkb2p2cCIsImEiOiJja2xsMDN1ZXowN2cxMnVsY3FpZzllcTB3In0.VqhrTGX7rFTgSeROj01uzQ';
        let centerPos = [-76.97092583229217,-12.095660770433788];
        let zoomPos = 15;
        if(window.innerWidth < 740){
            centerPos = [-76.97092583229217,-12.095660770433788]; 
            zoomPos = 14;
        }
        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/eduardojvp/ckxuqgn3j3qyv14qd8c9ykccr', 
            center: centerPos,
            zoom: zoomPos
        });
        var size = 200;
        
        var pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),
            
            onAdd: function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },
        
            render: function () {
                var duration = 2000;
                var t = (performance.now() % duration) / duration;
                var radius = (size / 2) * 0.3;
                var outerRadius = (size / 2) * 0.7 * t + radius;
                var context = this.context;
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(this.width / 2,this.height / 2,outerRadius,0,Math.PI * 2);
                context.fillStyle = 'rgba(1, 153, 216,' + (0.5 - t) + ')';
                context.fill();
                context.beginPath();
                context.arc(this.width / 2,this.height / 2,radius,0,Math.PI * 2);
                context.fillStyle = '#0199d8';
                context.strokeStyle = '#0199d8';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();
                this.data = context.getImageData(0,0,this.width,this.height).data;
                map.triggerRepaint();
            
                return true;
            }
        };
        
        var geojson = {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: this.state.ubicaciones
            }
        }
        geojson.data.features.forEach(function (marker,index) {
            var el = document.createElement('div');
            el.className = `marker ${marker.properties.project} marker-${marker.properties.index}`;
            if(marker.properties.project != 'ativa' || marker.properties.project != 'epiqe'){
                el.innerHTML = `<span>${marker.properties.index}<span>`
            }
            if(marker.properties.project == 'epiqe'){
                el.innerHTML = `<img src='${require("../../assets/images/point.svg").default}'/>`;
            }
            if(marker.properties.project == 'ativa'){
                el.innerHTML = `<img src='${require("../../assets/images/mapa_ativa.svg").default}'/>`;
            }
            
            new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);

        })
        let filterCategory = this.state.ubicaciones.filter((e)=>{
            if(e.properties.section == 'Estudios'){
                return e
            }
        })
        this.setState({map:map,category:filterCategory})
        
    }
    changeCategory=(category)=>{
        let filterCategory = this.state.ubicaciones.filter((e)=>{
            if(e.properties.section == category){
                return e
            }
        })
        this.setState({category:filterCategory,indexCategory:0})
        let time = setInterval(() => {
            this.updatePosition(0)
            clearInterval(time)
        }, 200);
    }
    updatePosition =(pos)=>{
        this.state.map.flyTo({
            center: this.state.category[pos].geometry.coordinates,
            zoom: 17,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        });
    }
    prevStep =()=>{
        if(this.state.indexCategory > 0){
            this.setState({indexCategory:this.state.indexCategory-1})
            this.updatePosition(this.state.indexCategory-1)
        }else{
            this.setState({indexCategory:this.state.category.length-1})
            this.updatePosition(this.state.category.length-1)
        }
    }
    nextStep =()=>{
        if(this.state.indexCategory < this.state.category.length-1){
            this.setState({indexCategory:this.state.indexCategory+1})
            this.updatePosition(this.state.indexCategory+1)
        }else{
            this.setState({indexCategory:0})
            this.updatePosition(0)
        }
    }
    render() {
        return (
            <section className='section-map'>
                <div className='content-controls'>
                    <div className='content-title'>
                        <p className='title-map'><span className='c-secondary Miftah'>Ubicación</span> <span className='c-primary'>privilegiada</span></p>
                        <div className='icons'>
                            {
                                this.state.icons.map((item,index)=>{
                                    return(
                                        <div className={`item ${this.state.category[0].properties.section == item.project ? 'active' : ''}`} key={'icon-'+index} onClick={()=>{this.changeCategory(item.project)}}>
                                            <img className='img' src={item.icon}></img>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <MediaQuery query='(max-width:499px)'>
                        <div className='content-map'>
                            <div className='content-map-external'>
                                <a href='https://ul.waze.com/ul?ll=-12.09941688%2C-76.97098732&navigate=yes&zoom=14&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location' className='item-external'>
                                    <img className='' src={require('../../assets/images/iconos/waze.svg').default} ></img>
                                </a>
                                <a href='https://ul.waze.com/ul?ll=-12.09941688%2C-76.97098732&navigate=yes&zoom=14&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location' className='item-external'>
                                    <img className='' src={require('../../assets/images/iconos/google.svg').default} ></img>
                                </a>
                            </div>
                            <div id="map"></div>
                        </div>
                    </MediaQuery>
                    <div className='controls'>
                        <div className='content-controls'>
                            <span className='title-down Miftah c-primary'>{this.state.category[this.state.indexCategory].properties.section}</span>
                            <div className='content-slide'>
                                <img className='' src={require('../../assets/images/inicio/icons/signal-left.svg').default} onClick={()=>{this.prevStep()}}></img>
                                <span className='c-primary'>{`${this.state.category[this.state.indexCategory].properties.index}. ${this.state.category[this.state.indexCategory].properties.project}`}</span>
                                <img className='' src={require('../../assets/images/inicio/icons/signal-right.svg').default} onClick={()=>{this.nextStep()}}></img>
                            </div>
                            <div className='down-points'>
                                <div className='owl-dots'>
                                    {
                                        this.state.category.map((item)=>{
                                            return(
                                                <div className={`owl-dot ${this.state.category[this.state.indexCategory].properties.project == item.properties.project ? 'active' : ''}`}>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MediaQuery query='(min-width:500px)'>
                    <div className='content-map'>
                        <div className='content-map-external'>
                            <a href='https://goo.gl/maps/eR9HqW82SZyoanQU9' className='item-external' target='_blank'>
                                <img className='' src={require('../../assets/images/iconos/google.svg').default} ></img>
                            </a>
                            <a href='https://ul.waze.com/ul?ll=-12.09825243%2C-76.97167397&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location' className='item-external' target='_blank'>
                                <img className='' src={require('../../assets/images/iconos/waze.svg').default} ></img>
                            </a>
                            
                        </div>
                        <div id="map"></div>
                    </div>
                </MediaQuery>
            </section>
        )
    }
}
