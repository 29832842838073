import axios from 'axios';

export default class ApiService {
    constructor(){
        this.$api = axios.create({
            baseURL:  process.env.NODE_ENV == "production" ? "/api" : "http://localhost:3000",
            // baseURL:  "https://prueba.ativa.com.pe/api",
            headers: {
                "Cache-Control": "no-cache",
                'Content-Type': 'application/json',
            }
        })
    }
    saveLead = async payload => {
        try {
            const data = await this.$api.post("/leads", {data: payload})

            window.dataLayer?.push({
                event: 'Lead',
                ...payload.lead_location && {lead_location: payload.lead_location},
                ...payload.rooms_amount && {rooms_amount: payload.rooms_amount},
                ...payload.phase && {phase: payload.phase},
                ...payload.motive && {motive: payload.motive},
                ...payload.promo && {promotion: payload.promo},
            })

            return data
        } catch (error) {
            console.error(error)
            throw error
        }
    }
    saveComplaint = payload => this.$api.post("/complaints", {data: payload})
    post = (url, payload) => this.$api.post(url, payload);
    get = (url) => this.$api.get(url);
}