import React, { Component } from 'react';
import './areas.scss';
import MediaQuery from 'react-responsive'
import OwlCarousel from "react-owl-carousel2"
import { connect } from 'react-redux';
import { updateComun,updateInterior } from '../../data/components/areas';
import ApiService from '../actions/services/ApiService';
import $ from 'jquery'
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';

const signal_left =  require("../../assets/images/inicio/icons/signal-left.svg").default
const signal_right =  require("../../assets/images/inicio/icons/signal-right.svg").default

 class Areas extends Component {
    constructor(props){
        super(props)
        this._api = new ApiService()
        this.onChangedComun = this.onChangedComun.bind(this);
        this.onChangedInterior = this.onChangedInterior.bind(this);
        this.state = {
            eventsInterior:{onDragged:this.onDragged,onChanged:this.onChangedInterior},
            eventsComun:{onDragged:this.onChanged,onChanged:this.onChangedComun},
            options:{
                items:1,
                rewind: false,
                loop:false,
                nav:true,
                center:false,
                autoWidth:true,
                speed:1000,
                smartSpeed:300,
                dots:true,
                navText: [
                    `<img src=${signal_left}></img>`,
                    `<img src=${signal_right}></img>`
                ]
            },
            comunes:[
                {alt:'',title:'Lobby',icon:require("../../assets/images/inicio/slider/comunes/lobby.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Lobby Derby-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sala de Espera',icon:require("../../assets/images/inicio/slider/comunes/sala-de-espera.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sala de Espera-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Galería de ingreso',icon:require("../../assets/images/inicio/slider/comunes/sala-de-espera.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Galería de ingreso-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Fire Place',icon:require("../../assets/images/inicio/slider/comunes/fire-place.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Fire Place-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sala Hollywood',icon:require("../../assets/images/inicio/slider/comunes/sala-holli.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sala Hollywood-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sala de Mujeres',icon:require("../../assets/images/inicio/slider/comunes/sala-de-mujeres.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sala de Mujeres-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Think Box',icon:require("../../assets/images/inicio/slider/comunes/think-box.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Think Box-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Recording Room',icon:require("../../assets/images/inicio/slider/comunes/recording-room.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Recording Room-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sala de Reuniones',icon:require("../../assets/images/inicio/slider/comunes/sala-de-reuniones.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sala de reuniones-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Home School',icon:require("../../assets/images/inicio/slider/comunes/home-school.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Home School-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sala Lego',icon:require("../../assets/images/inicio/slider/comunes/sala-lego.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sala Lego-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Patio de Niños',icon:require("../../assets/images/inicio/slider/comunes/patio-child.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Patio de Niños-100.jpg"),imageMobile:'',link:'',type:''},
                // {alt:'',title:'Sky Bar',icon:require("../../assets/images/inicio/slider/comunes/sky-bar.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sky Bar-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sky Bar',icon:require("../../assets/images/inicio/slider/comunes/sky-bar.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sky Bar 2-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Juicy Bar',icon:require("../../assets/images/inicio/slider/comunes/juice.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Juicy Bar-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Terraza de Pizza',icon:require("../../assets/images/inicio/slider/comunes/pizza.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Terraza de Pizza-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'BBQ y Grill',icon:require("../../assets/images/inicio/slider/comunes/bbq-grill.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/BBQ y Grill-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Sushi Box',icon:require("../../assets/images/inicio/slider/comunes/sushi-box.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Sushi Box-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Gimnasio',icon:require("../../assets/images/inicio/slider/comunes/gimnasio.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Gimnasio-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Zona de Box',icon:require("../../assets/images/inicio/slider/comunes/zona-box.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Zona de Box-100.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Zona de Bicicletas',icon:require("../../assets/images/inicio/slider/comunes/zona-de-bici.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/comunes/Zona de Bicicletas-100.jpg"),imageMobile:'',link:'',type:''},
            ],
            interiores:[
                {alt:'',title:'Sala',icon:require("../../assets/images/inicio/slider/interiores/sala.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/interiores/Sala.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Cocina - Comedor',icon:require("../../assets/images/inicio/slider/interiores/cocina.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/interiores/Comedor y cocina.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Dormitorio Principal',icon:require("../../assets/images/inicio/slider/interiores/dormitorio-principal.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/interiores/Dormitorio principal.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Dormitorio Secundario ',icon:require("../../assets/images/inicio/slider/interiores/dormitorio-principal.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/interiores/Dormitorio secundario.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Baño Principal ',icon:require("../../assets/images/inicio/slider/interiores/bath-principal.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/interiores/Bathprincipal.jpg"),imageMobile:'',link:'',type:''},
                {alt:'',title:'Baño Secundario ',icon:require("../../assets/images/inicio/slider/interiores/bath-principal.svg").default,id:'',imageDesk:require("../../assets/images/inicio/slider/interiores/Bathsecundario.jpg"),imageMobile:'',link:'',type:''},
            ],
        }
    }
    onChangedInterior(e){
        
        if(e.page.index >= 0){
            this.props.updateInterior(e.page.index)
        }
    }
    onChangedComun(e){
        if(e.page.index >= 0){
            $('.point').removeClass('activated')
            $(`.point-${e.page.index}`).addClass('activated')
            $("#icon-comun").attr("src",this.state.comunes[e.page.index].icon);
            $("#title-comun").text(this.state.comunes[e.page.index].title);
        }
        try {
            var owl = $('.carousel-point-tipologia').owlCarousel();
            let time = setInterval(() => {
                let index = parseInt(document.querySelector(".carousel-point-tipologia .activated").dataset.index);
                let actives = document.querySelectorAll(".carousel-point-tipologia .owl-item.active")
                if(index > parseInt(actives[actives.length-1].childNodes[0].dataset.index)){
                    owl.trigger('next.owl.carousel');
                }
                if(index < parseInt(actives[0].childNodes[0].dataset.index)){
                    owl.trigger('prev.owl.carousel');
                }
                
                clearInterval(time)
            }, 200);
        } catch (err) {
            console.log(err)
        }
        
    }
    componentDidMount(){

        $('.areas-comunes .content-slider').lightGallery({
            selector: '.content-picture',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
        $('.interiores .content-slider').lightGallery({
            selector: '.content-picture',
            thumbnail:true,
            animateThumb: false,
            showThumbByDefault: false,
            subHtmlSelectorRelative: true,
            rotate:false,
            actualSize:false,
            download:true,
            pinterest:false,
            googlePlus:false,
            twitter:false,
        });
    }
    response =(area)=>{
        this.setState({comunes:area.data})
    }
    responseInterior =(area)=>{
        this.setState({interiores:area.data})
    }
    render() {
        return (
            <section className='areas'>
                <div className='areas-comunes container'>
                    <div className='content-title'>
                        <img className='img movil' src={require('../../assets/images/inicio/icons/back-areas.png')} ></img>
                        <span className='t-area c-white'> <span className='regular'>Para escaparte</span> de lo convencional</span>
                        <span className='t-area c-white Miftah'>Áreas comunes</span>
                        <MediaQuery query='(min-width:500px)'>
                            <ControlsComunes comunes={this.state.comunes}></ControlsComunes>
                        </MediaQuery>
                    </div>
                    <div className='content-slider'>
                        <OwlCarousel options={this.state.options} events={this.state.eventsComun} className="owl-carousel MyCarouselHorizontal" >
                            {
                                this.state.comunes.map((item,index)=>{
                                    return(
                                        <div className='content-picture' key={'slider'+index} href={item.imageDesk}>
                                            <picture className="content-img">
                                                <source media="(min-width: 500px)" srcSet={item.imageDesk}></source>
                                                <img src={item.imageDesk}></img>
                                            </picture>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                    <MediaQuery query='(max-width:499px)'>
                        <ControlsComunes comunes={this.state.comunes}></ControlsComunes>
                    </MediaQuery>
                </div>
                <div className='interiores container'>
                    <div className='content-title'>
                        <span className='c-secondary'> <span className='t-area Miftah'>Interiores</span></span>
                        <span className='c-primary'>Para disfrutar sin límites</span>
                        <MediaQuery query='(min-width:500px)'>
                            <ControlsInteriores interiores={this.state.interiores}></ControlsInteriores>
                        </MediaQuery>
                    </div>
                    <div className='content-slider'>
                        <OwlCarousel options={this.state.options} events={this.state.eventsInterior} className="owl-carousel MyCarouselHorizontal" >
                            {
                                this.state.interiores.map((item,index)=>{
                                    return(
                                        <div className='content-picture' key={'slider'+index} href={item.imageDesk}>
                                            <picture className="content-img">
                                                <source media="(min-width: 500px)" srcSet={item.imageDesk}></source>
                                                <img src={item.imageDesk}></img>
                                            </picture>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                    <MediaQuery query='(max-width:499px)'>
                        <ControlsInteriores interiores={this.state.interiores}></ControlsInteriores>
                    </MediaQuery>
                </div>
            </section>
        )
    }
}
export default connect(null,{updateComun,updateInterior})(Areas)

class Controls extends Component{
    constructor(props){
        super(props)
        this.state={
            optionsItems:{
                items:5,
                rewind: false,
                loop:false,
                nav:false,
                center:false,
                autoplay:false,
                smartSpeed:600,
                autoplaySpeed:2000,
                dots:true,
                mouseDrag:false,
            }
        }
    }
    componentDidMount(){
    }
    render(){
        return(
            <>
                <div className='description'>
                    <div className='icon'>
                        <img className='icon-description' src={this.props.comunes[this.props.area.indexComun].icon} id="icon-comun"></img>
                        <span className='title-description Miftah c-secondary' id='title-comun'>{this.props.comunes[this.props.area.indexComun].title}</span>
                    </div>
                    {/* <p className='paragraph'>
                        {
                            this.props.comunes[this.props.area.indexComun].paragraph
                        }
                    </p> */}
                </div>
                <div className='points'>
                    <OwlCarousel options={this.state.optionsItems} events={this.state.eventsInterior} className="owl-carousel carousel-point-tipologia" >
                        {
                            this.props.comunes.map((item,index)=>{
                                return(
                                    <div className={`point point-${index} ${index == 0 ? 'activated':''}`} data-index={index}>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
            </>
        )
    }
}
const MapStateProps = (state) =>{
    return({
        area:state.areas
    })
};
const ControlsComunes = connect(MapStateProps,null)(Controls)

class ControlsInterior extends Component{
    componentDidMount(){
    }
    render(){
        return(
            <>
                <div className='description'>
                    <div className='icon'>
                        <img className='icon-description' src={this.props.interiores[this.props.area.indexInterior].icon} ></img>
                        <span className='title-description Miftah c-primary'>{this.props.interiores[this.props.area.indexInterior].title}</span>
                    </div>
                </div>
                <div className='points'>
                    <div className='owl-dots'>
                        {
                            this.props.interiores.map((item)=>{
                                return(
                                    <div className={`owl-dot ${item.title == this.props.interiores[this.props.area.indexInterior].title ? 'active':''}`}>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}

const ControlsInteriores = connect(MapStateProps,null)(ControlsInterior)
