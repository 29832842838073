import React, { Component } from 'react'
import "./reclamacion.scss"
import Swal from "sweetalert2"
import { FormContainer, setInputProps } from '../../components/common/forms/Form'
import { ComplaintsBookValidatonSchema } from '../../components/common/forms/constraints/ValidatonSchemas'
import ApiService from '../../components/actions/services/ApiService'

export default class Reclamacion extends Component {
    constructor() {
        super()
        this.submitForm = this.submitForm.bind(this)
        this._api = new ApiService()
    }
    initialValues = {
        fname: "",
        lname: "",
        mlname: "",
        email: "",
        phone: "",
        idType: "",
        idNumber: "",
        region: "",
        province: "",
        district: "",
        address: "",
        typeOfGood: "",
        orderNumber: "",
        amount: "",
        goodDescription: "",
        complaintType: "",
        complaintDetail: "",
        consumerPetiton: "",
        sellerAction: "",
        terms: true
    }
    submitForm(values, {setSubmitting}) {
        this._api.saveComplaint(values)
                .then(({data}) => {
                    setSubmitting(false)
                    Swal.fire({
                        title: '',
                        text: 'Datos enviados',
                        icon: 'success',
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#feecde'
                    })
                })
                .then(({response}) => {
                    setSubmitting(false)
                    Swal.fire({
                        title: '',
                        text: '¡Error! Intentelo más tarde',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: 'red'
                    })
                })
    }

    componentDidMount(){

    }
    render() {
        return(
        <FormContainer
        initialValues={this.initialValues}
        validationSchema={ComplaintsBookValidatonSchema}
        onSubmit={this.submitForm}>
        {form => {const {handleSubmit} = form;
        return (
            <main className="page --complaints-book">
                <form onSubmit={handleSubmit}>
                    <div className="form-group col-xs-12">
                        <h1 className="tit-libro text-left text-center text-uppercase">Libro de Reclamaciones</h1>
                        <p className="descripcion color-blanco text-left tit-custom-dos">Conforme a lo establecido en el Código de
                            Protección y Defensa del Consumidor, esta institución cuenta con un Libro de Reclamaciones a su
                            disposición.
                        </p>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("fname", "form-control large", form)} placeholder="Nombre*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("lname", "form-control large", form)} placeholder="Apellido Paterno*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("mlname", "form-control large", form)} placeholder="Apellido Materno*" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-3 col-lg-3">
                            <input type="email" name="email"  {...setInputProps("email", "form-control large", form)} placeholder="Correo Electrónico*" />
                        </div>
                        <div className="form-group col-sm-3 col-lg-3">
                            <input type="tel" {...setInputProps("phone", "form-control large", form)} placeholder="Celular*" />
                        </div>
                        <div className="form-group col-sm-3 col-lg-3 no-padding-right no-padding-left">
                            <select name="id_type" {...setInputProps("idType", "form-control", form)} >
                                <option value="">Seleccionar</option>
                                <option value="DNI">DNI</option>
                                <option value="RUC">RUC</option>
                                <option value="Carnet de Extranjería">Carnet de Extranjería</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-3 col-lg-3 no-padding-right no-padding-left">
                            <input type="text" {...setInputProps("idNumber", "form-control large", form)} placeholder="N° documento" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("region", "form-control large", form)} placeholder="Departamento*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("province", "form-control large", form)} placeholder="Provincia*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("district", "form-control large", form)} placeholder="Distrito*" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <input type="text" {...setInputProps("address", "form-control large", form)} placeholder="Dirección*" />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <h2 className="text-left">IDENTIFICACIÓN DEL BIEN CONTRATADO</h2>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-4 col-lg-4">
                            <select name="contracted_good_type" {...setInputProps("typeOfGood", "form-control", form)} >
                                <option value="" selected="">Tipo de Bien Contratado*</option>
                                <option value="Producto">Producto</option>
                                <option value="Servicio">Servicio</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" name="order_number" {...setInputProps("orderNumber", "form-control large", form)} placeholder="Nº de Pedido*" />
                        </div>
                        <div className="form-group col-sm-4 col-lg-4">
                            <input type="text" {...setInputProps("amount", "form-control large", form)} placeholder="Monto*" />
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div className="form-group col-lg-12 col-sm-12">
                            <textarea type="text" {...setInputProps("goodDescription", "form-control large textarea", form)} placeholder="Descripción*"  />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <h2 className="text-left">DETALLE DE LA RECLAMACIÓN</h2>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <select name="complaint_type" {...setInputProps("complaintType", "form-control", form)} >
                                <option selected disabled>Tipo de Reclamo*</option>
                                <option value="Reclamo">Reclamo</option>
                                <option value="Queja">Queja</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <input type="text" {...setInputProps("consumerPetiton", "form-control large", form)} placeholder="Pedido del Consumidor*"  />
                        </div>
                    </div>
                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <textarea type="text" {...setInputProps("complaintDetail", "form-control large textarea", form)} placeholder="Descripción*" />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <h2 className="text-left">ACCIONES ADOPTADAS POR EL VENDEDOR</h2>
                    </div>

                    <div class="form-row">
                        <div className="form-group col-sm-12">
                            <textarea type="text" {...setInputProps("sellerAction", "form-control large textarea", form)} placeholder="Descripción*"  />
                        </div>
                    </div>

                    <div className="form-group col-xs-12">
                        <div className="col-xs-12">
                            <div className="checkbox">
                                {/* <input type="checkbox" name="politicas" value="politicas aceptadas" /> */}
                                <label for="politicas" className="check">
                                <input type="checkbox" checked/>
                                &nbsp;He leído y acepto la <a target="_blank" href="./politica-de-privacidad.pdf" class="Primary">Política de Datos Personales</a>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-xs-12 flex-center">
                        <button type="submit" className="form-submit btnSubmitLibro" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando..." : "Enviar"}</button>
                    </div>

                    <div className="block-answer col-xs-12">
                        <div className="values">
                            <div className="error-message">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
            )}}
             </FormContainer>
        )
    }
}
