import { useEffect, useRef, useState } from 'react';
import './floatform.scss';
// import sr from '../../../components/reveal';
// import sr from './scrollreveal';
import MediaQuery from 'react-responsive';
import { FormContainer } from '../common/formik';
import { IconMask } from './iconmask';
import { baseSchema } from './schema';
import { FormStyled } from './formStyled';
import { InputText } from './inputtext';
import { InputChecked } from './inputChecked';
import { animation } from '../../common/helpers';
import ApiService from '../actions/services/ApiService';
const politicasPdf = require("../../assets/files/Politica-de-privacidad.pdf");
const terminosycondiciones = require("../../assets/files/TERMINOS-Y-CONDICIONES-ATIVA.pdf");
let index = 0;
export const FloatForm = () => {
	const apiService = new ApiService();
	const ContentImg = useRef(null);
	const [openForm, setOpenForm] = useState(true);
	const initialValues = {
		fname: '',
		lname: '',
		phone: '',
		email: '',
	};

	const onSubmit = async (values, form) => {
		try {
			values.lead_location = 'sicky_form'
			await apiService.saveLead(values)

			form.resetForm()
			window.location = '/gracias'
		} catch (error) {
			console.error(error)
		} finally {
			form.setSubmitting(false)
		}
	}

	const listFrases = ['Adquiere tu depa aquí', 'Tu nuevo depa en Surco te espera', 'Empieza tu nueva vida aquí', 'Visítanos y conoce las mejores promos'];
	const changeFrase = () => {
		const frase = document.querySelector('.dinamic');
		if (frase) {
			index = index === listFrases.length - 1 ? 0 : index + 1;
			frase.innerHTML = animation(listFrases[index], '', ' ', false);
			let dinamic = document.querySelector('.dinamic');
			dinamic?.classList.remove('opacidad');
			let elements = document.querySelectorAll('.dinamic .animate');
			elements.forEach(element => {
				element.classList.add('active');
			});
			let addopacity = setInterval(() => {
				dinamic?.classList.add('opacidad');
				clearInterval(addopacity);
			}, 4500);
		}
	};
	const opacity = () => {
		alert('hola');
	};
	const comprobarPosicion = () => {
		const fixedElement = document.querySelector('.float-form');
		const footer = document.querySelector('footer');
		if (fixedElement && footer) {
			const fixedElementRect = fixedElement.getBoundingClientRect();
			const footerRect = footer.getBoundingClientRect();
			if (fixedElementRect.bottom >= footerRect.top) {
				fixedElement.classList.add('hidden');
			} else {
				fixedElement.classList.remove('hidden');
			}
		}
	};
	const toggleActiveForm = (value) => {
		setOpenForm(value);
	};
	useEffect(() => {
		window.addEventListener('scroll', comprobarPosicion);
		changeFrase();
		const intervalchangefrases = setInterval(changeFrase, 5000);
	}, []);
	return (
		<div className={`float-form ${openForm == true ? 'closed' : 'opened'}`} ref={ContentImg}>
			<FormContainer initialValues={initialValues} validationSchema={baseSchema} onSubmit={onSubmit}>
				{(form) => {
					const { handleSubmit, isSubmitting } = form;
					return (
						<FormStyled onSubmit={handleSubmit}>
								<div className='mask icon-close d-close' onClick={() => toggleActiveForm(true)}>
								</div>
							<div className='content-inputs-form flex flex-row' onClick={()=>{toggleActiveForm(false)}}>
								<div className='flex content-dinamic'>
									<div className='dinamic'></div>
									<span className='text-white title-down'>Adquiere tu depa aquí</span>
									{/* <IconMask className='icon-down bg-white' onClick={() => toggleActiveForm()} /> */}
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText name='fname' placeholder='Nombre*' form={form}></InputText>
									</div>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText name='lname' placeholder='Apellido*' form={form}></InputText>
									</div>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText type="tel" name='phone' placeholder='Celular*' form={form}></InputText>
									</div>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText name='email' placeholder='Correo*' form={form}></InputText>
									</div>
								</div>
								<div className='content-politics'>
									<div className='mr-3'>
										<InputChecked form={form} name='terms' color='#d1862a'></InputChecked>
									</div>
									<p className='md:text-1xl sm:text-0/9xl mobile:text-0/7xl  text-gray-300'>
										Acepto los{' '}
										<a href={terminosycondiciones} className='md:text-1xl sm:text-0/9xl mobile:text-0/7xl underline' target='_blank'>
											términos y condiciones
										</a>
										. Autorizo el tratamiento de mis datos según la 
										<a href={politicasPdf} target='_blank' className='underline md:text-1xl sm:text-0/9xl mobile:text-0/7xl md:mb-0 mb-6 text-gray-300'>
											política de privacidad
										</a>
									</p>
								</div>
							</div>
							<button type='submit' className='submit'>
								<MediaQuery maxWidth={1099}>
									<span className='text-white'>Enviar</span>
								</MediaQuery>
								<IconMask className='mask icon-signal-right bg-white' />
							</button>
						</FormStyled>
					);
				}}
			</FormContainer>
		</div>
	);
};
