import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    indexComun:0,
    indexInterior:0,
    referPoint:"",
}

const areas = createSlice({
    name:'areas',
    initialState,
    reducers:{
        updateComun:(state,value)=>{
            state.indexComun = value.payload
        },
        updateInterior:(state,value)=>{
            console.log(value.payload)
            state.indexInterior = value.payload            
        },
        addRefersPoint:(state,value)=>{
            state.referPoint = value.payload
        },
    }
});

export const  {updateComun,updateInterior,addRefersPoint } = areas.actions;
export default areas.reducer