import React, { Component, memo, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import * as Yup from 'yup';
import { checkableBoolProps, setInputProps } from '../../components/common/forms/Form';
import BaseContactForm from '../../components/common/forms/markup/BaseContactForm';
import { Fragment } from 'react';
import './departamentos.scss';
import { connect } from 'react-redux';
import $ from 'jquery';
import { updateInf, updateTipologiesData, updateInfLanzamiento, updateTipologiesDataLanzamiento } from '../../data/pages/departamentos';
import { fases } from '../inicio/script';
import { FormHomeSchema } from '../../components/common/forms/constraints/ValidatonSchemas';
import BaseContactFormHome from '../../components/common/forms/markup/BaseContactFormHome';
import { data } from './data';
const signal_left = require('../../assets/images/inicio/icons/signal-left.svg').default;
const signal_right = require('../../assets/images/inicio/icons/signal-right.svg').default;

const InfoTypo = ({index,typologies,features,fase}) => {
    const moveTo = () => {
		$('html, body').animate({
			scrollTop: $('.section-form').offset().top - 100
		}, 1000);
    }
    return (
        <div className='content-inf'>
            <div className='content-characteristic'>
                <div className='characteristic'>
                    <div className={`img-inf desktop mask mask-shape ${fase}`} ></div>
					<div className={`img-inf movil mask mask-shape ${fase}`} ></div>
                    <div className='title-characteristic'>
                        <span className='type secondary bold'>{typologies[index].name}</span>
                        <span className='dorms d-block'>{typologies[index].dorms}</span>
                        <span className='attr'>
                            {features.map((e, index) => {
                                return (
                                    <p className='paragraph' key={index + 'paragraph1'}>
                                        {e}
                                    </p>
                                );
                            })}
                        </span>
                        <span className='meters d-block'>
                            Área: <span className='f-bold'>{typologies[index].meters}</span>
                        </span>
                    </div>
                </div>
                <div className='sketch-typology'>
                    <img className='img-fluid' src={typologies[index].sketch}></img>
                </div>
            </div>
            <div
                className='btn-quote'
                onClick={() => {
                    moveTo();
                }}
            >
                Cotiza aquí
                <div className='icon '>
                    <img className='' src={require('../../assets/images/inicio/icons/line-right.svg').default}></img>
                </div>
            </div>
        </div>
    );

}

const MapStatePropsTypo = state => {
	return {
		data: state.departamentos,
	};
};

class Departamentos extends Component {
	constructor() {
		super();
		this.state = {
			dorms: 3,
			initialValues: {
				fname: null,
				lname: null,
				email: null,
				phone: null,
				terms: false,
				phase: '',
				rooms_amount: '',
				motive: '',
				message: '',
				lead_location: 'main_form',
			},
		};
	}
	componentDidMount() {}
	changeDorm = e => {
		this.setState({ dorms: e });
	};
	changeFase = e => {
		try {
			document.getElementsByName('rooms_amount')[0].value = '';
			this.setState(prevState => ({
				...prevState,
				initialValues: {
					...prevState.initialValues,
					phase: e.target.value,
				},
			}));
		} catch (error) {
			console.log(error);
		}
	};
	resetForm = () => {
		this.setState(prevState => ({
			initialValues: {
				fname: null,
				lname: null,
				email: null,
				phone: null,
				terms: false,
				phase: '',
				rooms_amount: '',
				motive: '',
				message: '',
			},
		}));
	};
	setterTerms = e => {
		this.setState(prevState => ({
			initialValues: {
				// object that we want to update
				...prevState.initialValues, // keep all other key-value pairs
				terms: true, // update the value of specific key
			},
		}));
	};
	setter = e => {
		this.setState(prevState => ({
			initialValues: {
				// object that we want to update
				...prevState.initialValues, // keep all other key-value pairs
				[e.target.name]: e.target.value, // update the value of specific key
			},
		}));

		let delay = setInterval(() => {
			console.log(this.state);
			clearInterval(delay);
		}, 1000);
	};
	render() {
		return (
			<main className='Typology ' id='section-tipologias'>
				<div className='content-title'>
					<span className='title title-1 c-primary'>Espacios diseñados</span>
					<span className='title title-2 Miftah c-secondary'>para soñar en grande</span>
				</div>
				<Control></Control>
				<section className='recorrido'>
					<img className='background-recorrido desktop' src={require('../../assets/images/departamentos/recorrido-background.png')}></img>
					<img className='background-recorrido movil' src={require('../../assets/images/departamentos/recorrido-background-movil.png')}></img>
					<span className='title-recorrido'>
						{' '}
						<span className='f-bold'>Sumérgete</span> en el arte de tu futuro depa <span className='Miftah '>Recorrido Virtual</span>
					</span>
					<div className='content-recorrido'>
						<div className='content-button'>
							<div
								className={`btn bold ${this.state.dorms == 2 ? 'active' : ''}`}
								onClick={() => {
									this.changeDorm(2);
								}}
							>
								2 DORMS
							</div>
							<div
								className={`btn bold ${this.state.dorms == 3 ? 'active' : ''}`}
								onClick={() => {
									this.changeDorm(3);
								}}
							>
								3 DORMS
							</div>
						</div>
						<div className='content-iframe'>
							<iframe
								className={`media ${this.state.dorms == 3 ? 'active' : ''}`}
								height='100%'
								width='100%'
								src={this.state.dorms == 3 ? 'https://www.ativa.com.pe/FINAL_360_X05/index.htm' : ''}
								title='YouTube video player'
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowfullscreen
							></iframe>
							<iframe
								className={`media ${this.state.dorms == 2 ? 'active' : ''}`}
								height='100%'
								width='100%'
								src={this.state.dorms == 2 ? 'https://www.ativa.com.pe/FINAL_360_X07/index.htm' : ''}
								title='YouTube video player'
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
								allowfullscreen
							></iframe>
							{/* <img className='' src={require('../../assets/images/departamentos/iframe.png')} ></img> */}
						</div>
					</div>
				</section>
				<section className='electrodomestic'>
					<span className='title-recorrido'>
						Tu depa ya viene <span className='c-third f-bold'> equipado y listo para disfrutar</span>
					</span>
					<div className='products'>
						<div className='content-button'>
							<div className='item'>
								<div className={`button`}>
									<img className='img-button' src={require('../../assets/images/aliados/horno.png')}></img>
								</div>
								<span className='title-button'>HORNO</span>
							</div>
							<div className='item'>
								<div className={`button campana`}>
									<img className='img-button' src={require('../../assets/images/aliados/campana.png')}></img>
								</div>
								<span className='title-button'>CAMPANA</span>
							</div>
							<div className='item'>
								<div className={`button`}>
									<img className='img-button' src={require('../../assets/images/aliados/encimera.png')}></img>
								</div>
								<span className='title-button'>ENCIMERA</span>
							</div>
							<div className='item'>
								<div className={`button`}>
									<img className='img-button' src={require('../../assets/images/aliados/microondas.png')}></img>
								</div>
								<span className='title-button'>MICROONDAS</span>
							</div>
							<div className='item'>
								<div className={`button`}>
									<img className='img-button' src={require('../../assets/images/aliados/ventana.png')}></img>
								</div>
								<span className='title-button text-center'>
									VENTANAS CON<br></br>REDUCCIÓN DE RUIDOS*
								</span>
							</div>
						</div>
					</div>
					<p className='nota-legal'>*Imagen referencial. Ventanas con reducción de ruido solo en dormitorios.</p>
				</section>
				<div className='landing'>
					<section className='section-form'>
						<img className='model' src={require('../../assets/images/inicio/icons/background-model.png')}></img>
						<img className='b-all' src={require('../../assets/images/background-all.png')}></img>
						<img className='b-left' src={require('../../assets/images/background-top-left.png')}></img>
						<img className='b-right' src={require('../../assets/images/background-top-right.png')}></img>
						<img className='b-bottom-right' src={require('../../assets/images/background-bottom-right.png')}></img>
						<div className='content-landing'>
							<div className='content-formulario'>
								<div className='content-title-form'>
									<span className='sub-title bold-alt'>¡Estás a un paso de alcanzar tu sueño!</span>
									<span className='sub-title-2 c-primary light'>Agenda una asesoría personalizada completando el siguiente formulario</span>
								</div>
								<BaseContactFormHome
									initialValues={this.state.initialValues}
									resetForm={this.resetForm}
									validationSchema={FormHomeSchema}
									render={form => (
										<Fragment>
											<input
												type='text'
												{...setInputProps('fname', 'input col', form)}
												onChange={e => {
													this.setter(e);
												}}
												placeholder='Nombres*'
											></input>
											<input
												type='text'
												{...setInputProps('lname', 'input col', form)}
												onChange={e => {
													this.setter(e);
												}}
												placeholder='Apellidos*'
											></input>
											<input
												type='tel'
												{...setInputProps('phone', 'input col', form)}
												onChange={e => {
													this.setter(e);
												}}
												placeholder='N° de celular*'
											></input>
											<input
												type='text'
												{...setInputProps('email', 'input col', form)}
												onChange={e => {
													this.setter(e);
												}}
												placeholder='Correo electrónico*'
											></input>
											<select
												{...setInputProps('phase', 'input col', form)}
												onChange={e => {
													this.changeFase(e);
												}}
											>
												<option value=''>Fase</option>
												{fases.map((item, index) => {
													return (
														<option value={item.value} key={index + 'fase'}>
															{item.name}
														</option>
													);
												})}
											</select>
											<select
												{...setInputProps('rooms_amount', 'input col', form)}
												onChange={e => {
													this.setter(e);
												}}
											>
												<option value=''>N° de dormitorios</option>
												{this.state.initialValues.phase
													? fases
															.filter(e => {
																return e.value == this.state.initialValues.phase;
															})[0]
															.dorms.map((item, index) => {
																return (
																	<option value={item.value} key={index + 'rooms'}>
																		{item.name}
																	</option>
																);
															})
													: null}
											</select>
											<select
												{...setInputProps('motive', 'input w-100', form)}
												onChange={e => {
													this.setter(e);
												}}
											>
												<option value=''>Motivo</option>
												<option value='Primera vivienda'>Primera vivienda</option>
												<option value='Inversión'>Inversión</option>
											</select>
											<span className='terms'>
												<input
													id={'check_terms'}
													{...checkableBoolProps('terms', 'checkbox', form)}
													onChange={e => {
														this.setterTerms(e);
													}}
													type='checkbox'
												></input>
												<label htmlFor={'check_terms'} className='label-accept'>
													<div className='check'></div>
												</label>
												Acepto las 
												<a href={require('../../assets/files/Politica-de-privacidad.pdf')} target='_blank' className='cursor-pointer'>
													Políticas de privacidad
												</a>
											</span>
										</Fragment>
									)}
								/>
							</div>
						</div>
					</section>
				</div>
			</main>
		);
	}
}

export default connect(null, null)(Departamentos);

function extrarNumber(string) {
	const numbers = string.match(/\d+/g); // Esto devuelve un array con todas las secuencias de dígitos
	return numbers[0];

}
const Control = () => {
    const [fase, setFase] = useState('fase1');
    const [dorms, setDorms] = useState(1);
    const [indexSlide, setIndexSlide] = useState(0);
    const [array, setArray] = useState(data[fase].find((e)=>e.position == dorms));
    const options = {
        rewind: true,
        loop:false,
        nav:true,
        center:false,
        autoWidth:true,
        speed:1000,
        smartSpeed:300,
        dots:true,
        margin:10,
        navText: [
            `<img src=${signal_left}></img>`,
            `<img src=${signal_right}></img>`
        ]
    }
    const changeFase = (fase) => {
        setFase(fase);
        setDorms(data[fase][0].position);
		console.log('changeFase',)
        setArray(data[fase].find((e)=>e.position == data[fase][0].position));
		const index = extrarNumber(fase);
		try {
			const select = document.querySelector("[name='phase']")
			select.selectedIndex = parseInt(index);
			select.dispatchEvent(new Event('change', { bubbles: true }));

			let delayDorms = setInterval(() => {
				const selectdorms = document.querySelector("[name='rooms_amount']");
				selectdorms.selectedIndex = parseInt(1);
				selectdorms.dispatchEvent(new Event('change', { bubbles: true }));
				clearInterval(delayDorms)
			},200)

		} catch (error) {
			
		}
    }
    const changeDorms = (dorms) => {
        setDorms(dorms);
        setArray(data[fase].find((e)=>e.position == dorms));
		const selectdorms = document.querySelector("[name='rooms_amount']");
		selectdorms.selectedIndex = data[fase].findIndex((e)=>e.position == dorms) + 1;
		selectdorms.dispatchEvent(new Event('change', { bubbles: true }));
    }
    const onchanged = (e) => {
        if(e.page.index >= 0){
            let delay = setInterval(() => {
                setIndexSlide(e.page.index );
                clearInterval(delay)
            }, 200);
        }
    }
    const events = {onChanged:onchanged}
    useEffect(() => {
		try {
			let delay = setInterval(() => {
				const select = document.querySelector("[name='phase']");
				select.selectedIndex = parseInt(1);
				select.dispatchEvent(new Event('change', { bubbles: true }));
				
				let delayDorms = setInterval(() => {
					const selectdorms = document.querySelector("[name='rooms_amount']");
					selectdorms.selectedIndex = parseInt(1);
					selectdorms.dispatchEvent(new Event('change', { bubbles: true }));
					clearInterval(delayDorms)
				},200)
				
				clearInterval(delay)
			},1000)
		} catch (error) {
			
		}
    },[])
	return (
		<div className='content-tipology'>
			<div className='d-flex f-column align-center justify-center'>
				<div className='d-flex f-column content-controls'>
					<span className='title-controls'>¿Cuántos dorms estas buscando?</span>
					<div className='content-btn d-flex f-row'>
						{data[fase].map((item, index) => {
							return (
								<div
									className={`btn-dorm btn-dorm-1 ${item.position == dorms ? 'active' : ''}`}
									onClick={e => {
										changeDorms(item.position);
									}}
									key={index + 'fase1'}
								>
									<div className='content-hover'>
										<span className='num bold-alt c-white'>{item.position}</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className='d-flex f-column content-controls'>
					<span className='mt-1 title-controls'>¿En qué fase estás interesado?</span>
					<div className='content-btn content-btn-fases d-flex f-row'>
						<div
							className={`btn-dorm btn-dorm-1 ${fase.includes('fase1') ? 'active' : ''}`}
							onClick={e => {
								changeFase('fase1');
							}}
						>
							<div className='content-hover'>
								<span className='num bold-alt c-white'>Fase 1</span>
							</div>
						</div>
						<div
							className={`btn-dorm ${fase.includes('fase2') ? 'active' : ''}`}
							onClick={e => {
								changeFase('fase2');
							}}
						>
							<div className='content-hover'>
								<span className='num bold-alt c-white'>Fase 2</span>
							</div>
						</div>
                        <div
							className={`btn-dorm ${fase.includes('fase3') ? 'active' : ''}`}
							onClick={e => {
								changeFase('fase3');
							}}
						>
							<div className='content-hover'>
								<span className='num bold-alt c-white'>Fase 3</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='content-slider-tipology'>
                
                <div className={`type-tipology d-flex active`}>
                    <ContainerSlider
                        typologies={array.tipologias}
                        events={events}
                        options={options}
                        changeTypo={fase}
                    />
                    <InfoTypo index={indexSlide} typologies={array.tipologias} features={array.features} fase={fase}></InfoTypo>
                </div>
               
            </div>
		</div>
	);
};

const ContainerSlider = ({typologies = [],events,options}) => {
    useEffect(() => {
        
    },[typologies])
	return (
		<div className={`content-img-typology`}>
			<div className={`content-slider  active`}>
                <OwlCarousel options={options} events={events} className='owl-carousel MyCarouselHorizontal2 owl-theme owl-loaded owl-drag' id='carousel-interiores2'>
                    {typologies.map((item, index) => {
                        return (
                            <div className='img-slider' href={item.img} key={index + 'tipology2'}>
                                <img className='' src={item.img}></img>
                            </div>
                        );
                    })}
                </OwlCarousel>
            </div>
		</div>
	);
};
