import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string().matches(/([A-Za-z\d])+/g, 'El nombre no debe contener números')
        .required(),
    lname: Yup.string().matches(/([A-Za-z\d])+/g, 'El nombre no debe contener números')
        .required(),
    email: Yup.string()
        .email()
        .required(),
    phone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}

export const ComplaintsBookValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        mlname: Yup.string()
            .required(),
        idType: Yup.string()
            .required(),
        idNumber: Yup.string()
            .required(),
        region: Yup.string()
            .required(),
        province: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        address: Yup.string()
            .required(),
        typeOfGood: Yup.string()
            .required(),
        orderNumber: Yup.string()
            .required(),
        amount: Yup.string()
            .required(),
        goodDescription: Yup.string()
            .required(),
        complaintType: Yup.string()
            .required(),
        complaintDetail: Yup.string()
            .required(),
        consumerPetiton: Yup.string()
            .required(),
        sellerAction: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const BaseValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ContactValidationSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        medium: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const HomeValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ProjectValidatonSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        project: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })

export const ConstrainsRegister = {
    username: Yup.string()
        .required(),
    phone: Yup.string().matches(/[\+?\-?0-9]{7,}/).required(),
    email: Yup.string().email().required(),
    password: Yup.string().required().min(8, 'Password is too short - should be 8 chars minimum.').matches(/[a-zA-Z]/, 'Your password justo contain Latin letters'),
    repassword: Yup.string().required().min(8, 'Password is too short - should be 8 chars minimum.').matches(/[a-zA-Z]/, 'Your password justo contain Latin letters'),
}
export const ConstrainsLogin = {
    email: Yup.string()
        .email()
        .required(),
    password: Yup.string().required().min(6, 'Password is too short - should be 6 chars minimum.'),
}

export const FAQContactValidatonSchema = (values) => Yup.object()
    .shape({
        ...ConstrainsLogin,

    })

export const RegisterValidatonSchema = (values) => Yup.object()
    .shape({
        ...ConstrainsRegister,

    })

// export const FormHomeSchema = (values) => Yup.object()
//     .shape({
//         ...BaseInterestedConstraints,
//         phase: Yup.string()
//             .required(),
//         rooms_amount: Yup.string()
//             .required(),
//         motive: Yup.string()
//             .required(),
//         terms: Yup.bool()
//             .oneOf([true]).required
//     })
export const FormHomeSchema = (values) => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        phase: Yup.string()
            .required(),
        rooms_amount: Yup.string()
            .required(),
        motive: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })