import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkableBoolProps, setInputProps } from '../../components/common/forms/Form'
import BaseContactForm from '../../components/common/forms/markup/BaseContactForm'
import * as Yup from 'yup';
import { Fragment } from 'react';
import { fases } from '../inicio/script';
import BaseContactFormHome from '../../components/common/forms/markup/BaseContactFormHome';
import { FormHomeSchema } from '../../components/common/forms/constraints/ValidatonSchemas';
class Promo extends Component {
    constructor(props){
        super(props);
        this.state = {
            promo:{img:'',date:''},
            initialValues:{
                fname: null,
                lname: null,
                email: null,
                phone: null,
                terms: false,
                phase:"",
                rooms_amount: "",
                motive: "",
                message: "",
                lead_location: 'promo_form'
            },
        }
    }
    
    resetForm = () => {
        this.setState(prevState => ({
            initialValues:{
                fname: null,
                lname: null,
                email: null,
                phone: null,
                terms: false,
                phase:"",
                rooms_amount: "",
                motive: "",
                message: ""
            },
        }))
    }
    componentDidMount(){
        let id = window.location.pathname.replace('/Promo/','')
        const promo =  this.props.promos.promos.filter((obj)=> {
            if(obj.link == id) return obj
        })[0]
        this.setState({
            promo: promo,
            initialValues: {...this.state.initialValues, promo: promo.name}
        })
    }
    changeFase =(e)=>{
        try{
            document.getElementsByName("rooms_amount")[0].value = "";
            this.setState(prevState=>({
                ...prevState,
                initialValues:{
                    ...prevState.initialValues,
                    phase: e.target.value
                }
            }));
        }catch(error){
            console.log(error)
        }
    }
     setter = (e)=>{
        this.setState(prevState => ({
            initialValues: {                   // object that we want to update
                ...prevState.initialValues,    // keep all other key-value pairs
                [e.target.name]:e.target.value       // update the value of specific key
            }
        }))
    }
    setterTerms = (e)=>{
        this.setState(prevState => ({
            initialValues: {                   // object that we want to update
                ...prevState.initialValues,    // keep all other key-value pairs
                terms: true     // update the value of specific key
            }
        }))
    }
    render() {
        const {promo} = this.state
        return(
            <div className='promo-landing landing'>
                <div className='content-img'>
                    <img className='' src={this.state.promo.img} ></img>
                </div>
                <div className='inf-promo'>
                    <Counter date={this.state.promo.date}></Counter>
                    <section className='section-form'>
                        <img className="b-all" src={require("../../assets/images/background-all.png")}></img>
                        <img className="b-left" src={require("../../assets/images/background-top-left.png")}></img>
                        <img className="b-right" src={require("../../assets/images/background-top-right.png")}></img>
                        
                        <img className="b-bottom-right" src={require("../../assets/images/background-bottom-right.png")}></img>
                        <div className="content-landing container">
                            <div className="content-formulario">
                                <div className='content-title-form'>
                                    <span className="sub-title">¡Sé parte de esta promo!</span>

                                </div>
                                {/* <BaseContactForm
                                    initialValues={{promo: promo.name, ...this.state.initialValues}}
                                    validationSchema={Yup.object().shape({
                                        ...promo?.fields?.reservation_date && {reservation_date: Yup.string().required()},
                                        ...promo?.fields?.reservation_time && {reservation_time: Yup.string().required()},
                                        ...promo?.fields?.rooms_amount !== false && {
                                            phase: Yup.number().required(),
                                            rooms_amount: Yup.string().required()
                                        },
                                        ...promo?.fields?.motive !== false && {motive: Yup.string().required()},
                                    })}
                                    render={(form) => (
                                    <Fragment>
                                        {
                                            promo?.fields?.reservation_date &&
                                            <select {...setInputProps("reservation_date", "input col", form)}>
                                                {promo.fields.reservation_date.map(([value, text]) =>
                                                    <option value={value} className="text-center">{text}</option>
                                                )}
                                            </select>
                                        }
                                        {   
                                            promo?.fields?.reservation_time &&
                                            <select {...setInputProps("reservation_time", "input col", form)}>
                                                {promo.fields.reservation_time.map(([value, text]) =>
                                                <option value={value} className="text-center">{text}</option>
                                                )}
                                            </select>
                                        }
                                        {
                                            promo?.fields?.rooms_amount !== false && 
                                            <>
                                                <select 
                                                    {...setInputProps("phase", "input col", form)}
                                                    onChange={(e)=>{this.changeFase(e)}} 
                                                >
                                                    <option value="">Fase</option>
                                                    {
                                                        fases.map((item,index)=>{
                                                            return(
                                                                <option value={item.value} key={index+"fase"}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <select {...setInputProps("rooms_amount", "input col", form)}>
                                                    <option selected value="">N° de dormitorios</option>
                                                    {
                                                        this.state.initialValues.phase ?
                                                        fases.filter((e)=>{return e.value == this.state.initialValues.phase})[0].dorms.map((item,index)=>{
                                                            return(
                                                                <option value={item.value} key={index+"rooms"}>{item.name}</option>
                                                            )
                                                        }) : null
                                                    }
                                                </select>
                                            </>
                                        }
                                        {
                                            promo?.fields?.motive !== false && 
                                            <select {...setInputProps("motive", "input w-100", form)}>
                                                <option selected value="">Motivo</option>
                                                <option>Primera vivienda</option>
                                                <option>Inversión</option>
                                            </select>
                                        }
                                    </Fragment>
                                )}/> */}
                                <BaseContactFormHome
                                initialValues={this.state.initialValues}
                                resetForm={this.resetForm}
                                validationSchema={
                                FormHomeSchema
                                }
                                render={(form) => (
                                    <Fragment>
                                        <input type="text" {...setInputProps("fname", "input col", form)} onChange={(e)=>{this.setter(e)}} placeholder="Nombres*"></input>
                                        <input type="text" {...setInputProps("lname", "input col", form)}  onChange={(e)=>{this.setter(e)}} placeholder="Apellidos*"></input>
                                        <input type="tel" {...setInputProps("phone", "input col", form)}  onChange={(e)=>{this.setter(e)}} placeholder="N° de celular*"></input>
                                        <input type="text" {...setInputProps("email", "input col", form)}  onChange={(e)=>{this.setter(e)}} placeholder="Correo electrónico*"></input>
                                        <select 
                                            {...setInputProps("phase", "input col", form)}
                                            onChange={(e)=>{this.changeFase(e)}} 
                                        >
                                            <option value="">Fase</option>
                                            {
                                                fases.map((item,index)=>{
                                                    return(
                                                        <option value={item.value} key={index+"fase"}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <select {...setInputProps("rooms_amount", "input col", form)} onChange={(e)=>{this.setter(e)}}>
                                            <option value="">N° de dormitorios</option>
                                            {
                                                this.state.initialValues.phase ?
                                                fases.filter((e)=>{return e.value == this.state.initialValues.phase})[0].dorms.map((item,index)=>{
                                                    return(
                                                        <option value={item.value} key={index+"rooms"} >{item.name}</option>
                                                    )
                                                }) : null
                                            }
                                        </select>
                                        <select {...setInputProps("motive", "input w-100", form)} onChange={(e)=>{this.setter(e)}}>
                                            <option value="">Motivo</option>
                                            <option value='Primera vivienda'>Primera vivienda</option>
                                            <option value='Inversión'>Inversión</option>
                                        </select>
                                        <span className="terms">
                                            <input id={"check_terms"} {...checkableBoolProps("terms", "checkbox", form)} onChange={(e)=>{this.setterTerms(e)}} type="checkbox"></input>
                                            <label htmlFor={"check_terms"} className="label-accept"><div className="check"></div></label>
                                            Acepto las <a href={require("../../assets/files/Politica-de-privacidad.pdf")} target="_blank" className="cursor-pointer" >Políticas de privacidad</a>
                                        </span>
                                </Fragment>
                            )}
                        />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}
const MapStateProps = (state)=>{
    return({
        promos:state.promotionStore
    })
}
export default connect(MapStateProps,null)(Promo)

class Counter extends Component{
    constructor(props){
        super(props);
        this.state = {
            time:'<div class="digits">00<span>DÍAS</span></div><div class="digits">00<span>HORAS</span></div><div class="digits">00<span>MINS</span></div><div class="digits">00<span>SEGS</span></div>',
        }
    }
    initCountDown =(date,timeBarra,promo)=>{
            let fin = new Date(date);
            let inicio = new Date(timeBarra);
            let total = fin.getTime() - inicio.getTime();
            let tiempo =  fin.getTime() - new Date().getTime(); 

            let tiempoTotal = Math.round(total/ (1000*60));
            let tiempoRestante = Math.round(tiempo/ (1000*60));

            let widthbarra =  (tiempoRestante / tiempoTotal)*100
            // console.log(widthbarra)
            document.querySelector(`.${promo}`) && (document.querySelector(`.${promo}`).style = `width:${widthbarra}%`)
            
            var end = new Date(date);
            var _second = 1000;
            var _minute = _second * 60;
            var _hour = _minute * 60;
            var _day = _hour * 24;
            var timer;
            var now = new Date();
            var distance = end - now;
            if (distance < 0) {
                clearInterval(timer);
                return;
            }
            var days = String(Math.floor(distance / _day));
            var hours = String(Math.floor((distance % _day) / _hour));
            var minutes = String(Math.floor((distance % _hour) / _minute));
            var seconds = String(Math.floor((distance % _minute) / _second));

            var dias = days.split("");
            var horas = hours.split("");
            var minutos = minutes.split("");
            var segundos = seconds.split("");


            if (dias.length == 1) {
                dias.unshift("0");
            } else {
                dias = dias;
            }
            if (horas.length == 1) {
                horas.unshift("0");
            } else {
                horas = horas;
            }
            if (segundos.length == 1) {
                segundos.unshift("0");
            } else {
                segundos = segundos;
            }
            if (minutos.length == 1) {
                minutos.unshift("0");
            } else {
                minutos = minutos;
            }
            if (minutos.length == 1) {
                minutos.unshift("0");
            } else {
                minutos = minutos;
            }
            if (minutos.length == 1) {
                horas.unshift("0");
            } else {
                dias = dias;
            }
            this.setState({time:`<div class="digits"> ${dias[0]}${dias[1]}<span>DÍAS</span></div><div class="digits"> ${horas[0]}${horas[1]}<span>HORAS</span></div><div class="digits">${minutos[0]}${minutos[1]}<span>MINS</span></div><div class="digits">${segundos[0]}${segundos[1]}<span>SEGS</span></div>`});
        
    }
    componentDidMount(){
        setInterval(() => {
            this.initCountDown(this.props.date.to,this.props.date.from,'promo1') // socios muvin;
        }, 1000);
        
    }
    render(){
        return(
            <div className='content-count-promo'>
                {/* <div className='content-title'>
                    <span className='border ultra'>Accede a esta promo</span>
                    <span className='ultra text-underline c-white'>antes de que acabe</span>
                </div> */}
                <div className='content-count'>
                    <span className='bold-alt c-white title-count' dangerouslySetInnerHTML={{__html:this.state.time}}>
                        
                    </span>
                    <div className='barra promo1'>
                    </div>
                </div>
            </div>
        )
    }
}
