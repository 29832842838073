import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import OwlCarousel from "react-owl-carousel2";
import './banner.scss';
const signal_left =  require("../../assets/images/inicio/icons/signal-left.svg").default
const signal_right =  require("../../assets/images/inicio/icons/signal-right.svg").default

export default class Banner extends Component {
    constructor(props){
        super(props)
        this.afterChange = this.afterChange.bind(this)
        this.state = {
            card:[
                {
                    project: "Midgo",
                    district:"Lince",
                    address:"Av. Arequipa 1890, Lince",
                    imgProject: require("../../assets/images/render/render_midgo.jpg"),
                    imgBrand: 'brand-midgo',
                    allFases:[{fase:"Fase 2",type:"En lanzamiento"}],
                    fases: "Lanzamiento",
                    fase: "Lanzamiento",
                    frase: "A pasos del <strong>C.C. El Polo</strong>",
                    valuation: "",
                    dorms: " 2 y 3",
                    dormsShow: "1, 2 y 2 dorms + sala",
                    meters: "40m² - 71m²",
                    views: {desde:"233",hasta:"150"},
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://midgo.cosapiinmobiliaria.com.pe/",
                    index: 1,
                    button:"Registrate aquí",
                },
                {
                    project: "Momen",
                    district: "Breña",
                    address:'Av. Aguarico 1251, Breña',
                    imgBrand: "brand-momen",
                    imgProject: require("../../assets/images/render/render-momen.jpg"),
                    allFases:[{fase:"Fase 2",type:"Preventa"}],
                    fases: "Preventa",
                    fase: "Preventa",
                    frase: "A pasos del <strong>C.C. El Polo</strong>",
                    valuation: "",
                    dorms: " 2 y 3",
                    dormsShow: "2 y 3 Dorms",
                    meters: "40m² - 71m²",
                    views: {desde:"120",hasta:"250"},
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://www.momen.cosapiinmobiliaria.com.pe/",
                    index: 1,
                    button:"Registrate aquí",
                },
                
                {
                    project: "nesta",
                    district:"Jesús María",
                    address:"Av. Salaverry 475",
                    imgBrand: "brand-nesta",
                    imgProject: require("../../assets/images/render/render-nesta.jpg"),
                    fase: "Entrega Inmediata",
                    valuation: "Éxito en ventas",
                    dorms: "1, 2 y 3 Dorms",
                    dormsShow: "1, 2 y 3 Dorms",
                    views: {desde:"715",hasta:"1524"},
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://nesta.com.pe/",
                    index: 3,
                    button:"Registrate aquí",
                },
                // {
                //     project: "sente",
                //     district:"Breña",
                //     address:"Jr. Centenario 175"    ,
                //     imgBrand: "brand-sente",
                //     imgProject: require("../../assets/images/render/render-sente.jpg"),
                //     fase: "Entrega Inmediata",
                //     valuation: "",
                //     dorms: "2 y 3 Dorms",
                //     dormsShow: "2 y 3 Dorms",
                //     views: {desde:"325",hasta:"474"},
                //     tendency: "Tendencia",
                //     all: "all",
                //     link: "https://sente.pe/",
                //     index: 4,
                //     button:"Registrate aquí",
                // },
                {
                    project: "Muvin",
                    district:"Lince",
                    address:"Av. Prolongación iquitos 1870",
                    imgBrand: "brand-muvin",
                    imgProject: require("../../assets/images/departamentos/render-muvin-target.jpg"),
                    fase: "Entrega Inmediata",
                    valuation: "",
                    dorms: "2 y 3 Dorms",
                    dormsShow: "2 y 3 Dorms",
                    views: {desde:"325",hasta:"474"},
                    tendency: "Tendencia",
                    all: "all",
                    link: "https://sente.pe/",
                    index: 5,
                    button:"Registrate aquí",
                },
                
            ],
            options:{
                items:1,
                rewind: true,
                loop:true,
                nav:true,
                center:false,
                autoWidth:true,
                speed:1000,
                smartSpeed:300,
                dots:true,
                navText: [
                    `<img src=${signal_left}></img>`,
                    `<img src=${signal_right}></img>`
                ]
            },
            settings:{
                dots: false,
                arrows:false,
                infinite: false,
                centerMode: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                afterChange:this.afterChange
            },
        }
    }
    afterChange(e){
        this.setState({activeSlider:e})
    }
    render() {
        return (
            <section className="banner container">
                {/* <img className='background-point-left' src={require('../../assets/images/Inicio/iconos/background-points.png')} ></img>
                <img className='background-point-right' src={require('../../assets/images/Inicio/iconos/background-lines-right.png')} ></img> */}

               <div className="content-banner">
                    
                    <div className="title-banner">
                        <p className="paragraph paragraph-1 gotham">
                            ¿Estás interesado en <span className="c-primary bold">otros proyectos? </span> 
                        </p>
                        <p className="paragraph paragraph-2 gotham">
                            Conoce todos  <span className="bold c-secondary">los que tenemos</span> 
                        </p>
                        <MediaQuery query='(min-width:500px)'>
                            <div className='background-link'>
                                <span className=''>Ingresa a</span>
                                <a href='https://xn--eldepadetussueos-kub.pe/' target='_blank'>
                                    <img className='' src={require('../../assets/images/inicio/icons/depa.png')} ></img>
                                </a>
                            </div>
                            <img className='background-link-cosapi' src={require('../../assets/images/inicio/icons/COSAPI.png')} ></img>
                        </MediaQuery>
                    </div>
                    
                    <div className="content-build">
                        <div className='content-info-slide projects'>
                            <OwlCarousel options={this.state.options} events={this.state.events} className="owl-carousel MyCarouselHorizontal" >
                                {
                                    this.state.card.map((e,index)=>{
                                        return(
                                            <div key={"card-item"} className={`card-item ${e.project}`}>
                                                <div className={`item-brand ${e.imgBrand}`}></div>
                                                <div className="content-render">
                                                    <img className="render" src={e.imgProject}></img>
                                                    <div className={`fase-content`}>
                                                        <div className={`fase b-${e.project}`}>
                                                            {e.fase}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="background-shadow">
                                                    <div className="details">
                                                        <div className="content-detail">
                                                            <div className={`detail-brand ${e.imgBrand}`}></div>
                                                            <a className="button" href={e.link}>
                                                                <div className={`add b-${e.project}`}></div>
                                                            </a>
                                                        </div>
                                                        <div className="content-detail">
                                                            <div className={`district b-${e.project}`}>{e.district}</div>
                                                            <div className={`dorms`}>
                                                                {e.dormsShow}
                                                            </div>
                                                        </div>
                                                        <div className="content-detail">
                                                            <div className={`content-place`}>
                                                                <span className="title-place" dangerouslySetInnerHTML={{__html: `${e.address}, ${e.district}`}}></span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
                <MediaQuery query='(max-width:499px)'>
                    <div className='background-link'>
                        <span className=''>Ingresa a</span>
                        <a href='https://xn--eldepadetussueos-kub.pe/' target='_blank'>
                            <img className='' src={require('../../assets/images/inicio/icons/depa.png')} ></img>
                        </a>
                    </div>
                </MediaQuery>
            </section>
        )
    }
}
