import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    promos: [
        {
            name: "Tu nueva vida",
            link: "tu-nueva-vida",
            titleBotton: "Quiero esta promo",
            img: require("../../assets/images/promotions/cuidemos-el-futuro.png"),
            date: { from: '07/01/2022', to: '03/28/2022' },
        },
    ]
}

const promotionStore = createSlice({
    name: 'promotionStore',
    initialState,
    reducers: {
        updatePromotions: () => {

        }
    }
});

export const { updatePromotions } = promotionStore.actions;
export default promotionStore.reducer