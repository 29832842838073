import { configureStore } from '@reduxjs/toolkit';
import routesFeatures from './routeStore';
import modalFeatures from './modalStore';
import ModalGallery from './galleryModal';
import userStore from './userStore';
import dashboardStore from './dashboardStore';
import sliderHead from './components/sliderHead';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import slider from './components/sliderMain'
import promotionStore from './components/promotionStore';
import departamentos from './pages/departamentos';
import areas from './components/areas'

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })

export default configureStore({
    reducer: {
        routesFeatures,
        modalFeatures,
        ModalGallery,
        userStore,
        dashboardStore,
        sliderHead,
        slider,
        promotionStore,
        departamentos,
        areas
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
