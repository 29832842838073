import React, { Component } from 'react'
import $  from 'jquery'
import './whatsapp.scss'
import Swal from "sweetalert2"
import ApiService from '../actions/services/ApiService'
import {  FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { BaseValidatonSchema } from '../common/forms/constraints/ValidatonSchemas'
import Phone from '../phone/phone'

export default class Whatsapp extends Component {
    constructor() {
        super()
        this._api = new ApiService()
        this.submitForm = this.submitForm.bind(this)
        this.state = {
            show:false,
            message:false,
        }
    }

    initialValues = {
        fname: null,
        lname: null,
        email: null,
        phone: null,
        project: null,
        phase: 17,
        terms: false
    }

    submitForm(values, { setSubmitting, resetForm }) {
        const form = document.querySelector(".content-contacto-whatsapp")
        
        values.url_query = localStorage.getItem("url_query")
        values.lead_location = 'whatsapp'
        this._api.saveLead(values)
            .then(({data: {lead}}) => {
                setSubmitting(false)
                // resetForm()
                window.location.href = `/gracias`
              
            })
            .catch(error => {
                setSubmitting(false)
                // resetForm()
                
                Swal.fire({
                    title: '',
                    text: '¡Error! Intentelo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            })
    }
    comprobarPosicion = () => {
        const fixedElement = document.querySelector('.svg');
        const footer = document.querySelector('footer');
        if (fixedElement && footer) {
            const fixedElementRect = fixedElement.getBoundingClientRect();
            const footerRect = footer.getBoundingClientRect();
            if (fixedElementRect.bottom >= footerRect.top) {
                fixedElement.classList.add('hidden');
            } else {
                fixedElement.classList.remove('hidden');
            }
        }
    };
    componentDidMount(){
        window.addEventListener('scroll', this.comprobarPosicion);
        $(document).ready(()=>{
            var optionsMov = [
                {section:".formulario",signal:"#svg1",class:"",nav:-200,style:"transition:.2s;transform:scale(0)"},
            ]
            function DetectSecction(options){
                if(document.querySelector("#svg1") && document.querySelector(".formulario")){
                    let navbarHeight = document.querySelector("#svg1").getBoundingClientRect().y-100; //LA ALTURA DEL NAVBAR
                    options.forEach(options => {
                        if($("#svg1").offset().top > $(".formulario").offset().top){
                            $("#svg1").attr("transform","scale(0)")
                            document.querySelector("#svg1").style="transform:scale(0);transition:0.1s"
                        }else{
                            document.querySelector("#svg1").style="transform:scale(1);transition:0.1s"
                        }
                    });
                }
            }    
            // $(window).scroll(function(){
            //     DetectSecction(optionsMov);
            // });
        })
        
    }
    whatsapp(){
        this.setState({
            show:!this.state.show
        })
    }
    render() {
        const acceptId = (Math.random() + 1).toString(36).substring(7)
        return (
            <div className="items contacto">
                <div className={this.state.show ? "svg wpp active" : "svg wpp" } id="svg1" data-active="false" onClick={this.whatsapp.bind(this)}>
                    {/* <Phone></Phone> */}
                    <div className="content-svg">
                            <img className='' id="whatsapp" data-active="true" src={require('../../assets/images/iconos/botton-whatsapp.png')}></img>
                    </div>
                </div>
                <div className={this.state.show ? "field active" : "field" }>
                        <FormContainer
                            initialValues={this.initialValues}
                            validationSchema={BaseValidatonSchema}
                            onSubmit={this.submitForm}>
                            {form => {const {handleSubmit} = form;
                            return(
                            <form className={`content-contacto-whatsapp ${this.state.message==true ? '--success':''}`} onSubmit={handleSubmit}>
                                <div className="close" onClick={this.whatsapp.bind(this)}></div>
                                <div className="content-formulario-contacto ">
                                    <span className="Secondary">Compártenos tus datos y te contactaremos por Whatsapp</span>
                                    <input {...setInputProps("fname", "input-contacto", form)} placeholder="Nombre*"></input>
                                    <input {...setInputProps("lname", "input-contacto", form)} placeholder="Apellidos*"></input>
                                    <input {...setInputProps("phone", "input-contacto", form)} placeholder="Teléfonos / Celular*" type="tel"></input>
                                    <input {...setInputProps("email", "input-contacto", form)} placeholder="Correo electrónico*"></input>
                                    <span className="terms">
                                        <input id={acceptId} {...checkableBoolProps("terms", "checkbox", form)} type="checkbox"></input>
                                        <label htmlFor={acceptId} className="label-accept"><div className="check"></div></label>
                                        <p className="condiciones">He leído y acepto la <a href="./politica-de-privacidad.pdf" target="_blank" className="c-third">política de datos personales.</a></p>
                                    </span>
                                    <button type="submit" class="btn-submit" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando..." : "Enviar"}
                                        <div className='icon'>
                                            <img className='' src={require('../../assets/images/inicio/icons/line-right.svg').default}></img>
                                        </div>
                                    </button>
                                </div>
                                <div className="content-message ">
                                    <span className="text-center d-block title Miftah c-secondary">¡Muchas gracias!</span>
                                    <span className="sub-title">
                                        Un asesor te contactará pronto o puedes escribirle haciendo clic aquí
                                    </span>
                                    <a target="__blank" href="" className="asesores man">
                                        <a class="hover-link">
                                            <span className="title-asesor">Maribel Ruiz</span>
                                            {/* <span className="adjectives">Viajera, le gusta bailar y el arroz con pollo</span> */}
                                        </a>
                                    </a>  
                                </div>
                            </form>
                            )}}
                        </FormContainer>
                    </div>
            </div>
        )
    }
}
