import React, { Component } from 'react'
import './sliderHead.scss';
import OwlCarousel from "react-owl-carousel2"
import { connect } from 'react-redux';
import axios from "axios";
import MouseScroll from '../UI/mouse-srcoll/mouse-scroll';
import ApiService from '../actions/services/ApiService';
const signal_left =  require("../../assets/images/inicio/icons/signal-left.svg").default
const signal_right =  require("../../assets/images/inicio/icons/signal-right.svg").default
class SliderHead extends Component {
    constructor(props){
        super(props)
        this._api = new ApiService()
        this.onDragged = this.onDragged.bind(this);
        this.state = {
            images:[
                {alt:'',icon:'',id:'',imageDesk:require("../../assets/images/inicio/slider/ativa_w.png"),imageMobile:require("../../assets/images/inicio/slider/ativa_m.png"),link:'',title:'',type:''},
                {alt:'',icon:'',id:'',imageDesk:require("../../assets/images/inicio/slider/slider_desktop_fase-3.png"),imageMobile:require("../../assets/images/inicio/slider/slider_mobile_fase-3.png"),link:'',title:'',type:''},
                {alt:'',icon:'',id:'',imageDesk:require("../../assets/images/inicio/slider/slider-sala-de-ventas-desktop.png"),imageMobile:require("../../assets/images/inicio/slider/slider-sala-de-ventas-mobile.png"),link:'',title:'',type:''},
                {alt:'',icon:'',id:'',imageDesk:require("../../assets/images/inicio/slider/slider-1-desktop.png"),imageMobile:require("../../assets/images/inicio/slider/slider-1.png"),link:'',title:'',type:''},
                // {alt:'',icon:'',id:'',imageDesk:require("../../assets/images/inicio/slider/bono-desktop.png"),imageMobile:require("../../assets/images/inicio/slider/bono-mobile.png"),link:'',title:'',type:''},
            ],
            events:{onDragged:this.onDragged,onChanged:function(item){}},
            options:{
                items:1,
                rewind: true,
                loop:true,
                nav:true,
                center:false,
                speed:1000,
                smartSpeed:300,
                dots:true,
                autoplay:true,
                navText: [
                    `<img src=${signal_left}></img>`,
                    `<img src=${signal_right}></img>`
                ]
            },
        }
    }
    componentDidMount(){
        // this._api.post("/slider/getSliders",{type:'slider-main'})
                //  .then(this.response)
    }
    response =(res)=>{
        console.log(res);
        this.setState({images:res.data})
    }
    onDragged(e){

    }
    render() {
        return (
            <div className='Slider-head'>
                <OwlCarousel options={this.state.options} events={this.state.events} className="owl-carousel MyCarouselHorizontal" >
                    {
                        this.state.images.length > 0 ? 
                        this.state.images.map((item,index)=>{
                            return(
                                <div className='content-picture' key={'slider'+index}>
                                    {/* {
                                        index === 0 ? 
                                        <a href='https://cyberdepadays.cosapiinmobiliaria.com.pe/' target="_blank" className='btn-slider'>
                                        </a> : null
                                    } */}
                                    <picture className="content-img">
                                        <source media="(min-width: 500px)" srcSet={item.imageDesk}></source>
                                        <img src={item.imageMobile}></img>
                                    </picture>
                                </div>
                            )
                        }) : null
                    }
                </OwlCarousel>
                <div className='icon-mouse'>
                    <MouseScroll></MouseScroll>
                </div>
            </div>
        )
    }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.sliderHead
        }
    )
}
export default connect(MapStateProps,null)(SliderHead)